import React, { useEffect } from "react";
import style from "./dashboard.module.scss";
import wallet from "../../../assets/images/dashboard/wallet-icon.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyDecimalFormat } from "../../helper";
import commission from "../../../assets/images/dashboard/commision-earned.png";
import payment from "../../../assets/images/dashboard/paymnt-rlsd.png";
import pending from "../../../assets/images/dashboard/pending.png";
import UserStore from "../../../store/UserStore";
import TilesFilter from "./TilesFilter";

const TileItems = React.memo((props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  console.log("TileItems", props);
  const history = useHistory();
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);

  // const FixedAmount = (props.amount * curr.value).toFixed(curr.precision)
  let amount = CurrencyDecimalFormat(props.amount * curr.value, curr.precision);
  console.log('sa a a :: ', props?.indexNo)
  const boxnameclass =
    props.boxname === 0
      ? style.lst_box1
      : props.boxname === 1
      ? style.lst_box2
      : props.boxname === 2
      ? style.lst_box3
      : style.lst_box4;
  const images =
    props.boxname === 0
      ? wallet
      : props.boxname === 1
      ? commission
      : props.boxname === 2
      ? payment
      : pending;

  const dashboardtileHandler = (e) => {
    e.preventDefault();
    if (props.text === "commision") {
      UserStore.checkUserEarnings = true;
    }
    history.push(props.to);
  };
  const FiterData = [
    {
      code: "all",
      value: "",
    },
    {
      code: "thisYear",
      value: "",
    },
    {
      code: "thisMonth",
      value: "",
    },
    {
      code: "thisWeek",
      value: "",
    },
  ];
  const filterChange = (key) => {
    props.filterChange(props.text, key);
  };

  const bgColorClass = ['bg-skye-blue', 'bg-light-orange', 'bg-light-green', 'bg-light-pink'];

  return (
    <>
      <div className={style.list_boxes_main}>
        <div className={`body-card bot-to-top ${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_dashboard_pannel_box} ${boxnameclass}`
              : `${style.dashboard_pannel_box} ${boxnameclass}`
          } p-0`}>
          {/* {props.filter && (
            <TilesFilter items={FiterData} onChange={filterChange} />
          )} */}
          <div className={`${style.listing_box_sec} tile-box ${bgColorClass[props?.indexNo]} p-0 mh-150-px`} onClick={dashboardtileHandler}>
            <div className="p-3 h-100">
              <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                  <div className="d-flex jusity-content-center" style={{height:'50px'}}>
                    <img src={images} style={{height:'inherit'}} />
                  </div>
                  <h4 className="text-white">{props.amount_withcurrency}</h4>
                  <h6 className="text-white">{t("Tails." + props.text)}</h6>
              </div>
            </div>
            {/* <div className={`${style.listing_box_ico_rnd} img-div`}>
              <img src={images} alt="" />
            </div>
            <div className={`body-label ${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_listing_box_head}`
                  : `${style.listing_box_head}`
              }`}>
              {t("Tails." + props.text)}
            </div>
            <div className={`${style.listing_box_cnout} d-none`}>{curr.currentCurr} {amount}</div>
            <div className={style.listing_box_cnout}>
              {props.amount_withcurrency}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
});
export default TileItems;
