import API from "../../api/api";

export default class travelPackageService {
  API_KEY = localStorage.getItem("apiKey");
  
  getTravelPackageList = async (id = 0) => {
    // return {
    //     status:true,
    //     data:[
    //         {
    //             id:1,
    //             package_name:'first travel package',
    //             package_price:24,
    //             package_installment:1000,
    //             package_description:'this is demo purpose of first package.',
    //             is_purchase:true
    //         },
    //         {
    //             id:2,
    //             package_name:'second travel package',
    //             package_price:35,
    //             package_installment:1000,
    //             package_description:'this is demo purpose of second package.',
    //             is_purchase:false
    //         },
    //         {
    //             id:3,
    //             package_name:'third travel package',
    //             package_price:50,
    //             package_installment:1000,
    //             package_description:'this is demo purpose of third package.',
    //             is_purchase:false
    //         }
    //     ]
    // }
    return await API.API.get(`Travel_package/travel_package_list?category_id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  getPaymentLink = async (id = 0) => {
    return await API.API.post('Travel_package/purchase_travel_package', {package_id:id})
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('purchaseData', JSON.stringify(res?.data?.data?.purchase_details))
          return res.data?.data?.url;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  paymentStatusCheck = async (paylod) => {
    return await API.API.post("Travel_package/payment_success", paylod)
      .then((res) => {
        console.log('travelpaymentresponse :: ', res)
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  getMyTravelPackages = async () => {
    return await API.API.get("Travel_package/travel_package_purchase_list")
      .then((res) => {
        console.log('travelsresponse success :: ', res)
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log('travelsresponse :: ', error));
  }
}
