import React from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import "react-data-table-component-extensions/dist/index.css";
import { Button } from 'react-bootstrap';
import travelPackageService from '../../../service/travelpackage';
import { useHistory } from 'react-router';

function MyTravelPackageTable(props) {
    let travel = new travelPackageService();
    const {t} = useTranslation();
    let history = useHistory();
    const Currency = useSelector(state=>state.curr)
    const columns = [
        {
            name: 'Action',
            selector: row => (
                <Button
                    onClick={() => {
                        renewTravelPackage(row?.travel_package_id)
                    }}
                >Renew</Button>
            ),
            //   width : "30%"
        },
        {
          name: 'Package name',
          selector: row => row.package_name
        },
        {
            name: 'Amount',
            selector: row => row.purchase_amount
        },
        {
            name: 'Purchase Date',
            selector: row => row.purchase_date
        },
        {
            name: 'Renew Date',
            selector: row => row.purchase_renew_date
        }
      ];    

      const renewTravelPackage = (package_id = 0) => {
        travel.getPaymentLink(package_id)
        .then(url => {
            if (url?.length > 0) {
                // alert('your order is renewed');
                // history.push('mytravelpackages');
                // window.location.href = url;
            }
        })
        
    }

    return (
        <div>
            <DataTable
                columns={columns}
                data={props.data}
                highlightOnHover
                progressPending={props.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                responsive
                paginationTotalRows={props.totalRows}
                noDataComponent={t('Common.noDataRecordsToDisplay')}
            />
        </div>
    )
}
export default MyTravelPackageTable
