import React from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
import loginBg from "../../assets/images/login_bg.png"

function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <div
      className={`login-page ${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5-c`
          : `${style.app} pt-5-c`
      }`}
      style={{backgroundImage:`url(${loginBg})`}}
    >
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <Brand />
        <Language classname="mb-4 w-100" />
        {children}
      </Container>
      <div
        className={`d-none ${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_lg_login_btn_btm_1}`
            : `${style.lg_login_btn_btm_1}`
        }`}
      >
        2022 © Michael E Jordan Group 
        {/* -{" "} */}
        {/* <a href="https://ioss.in/">
          {" "}
          Developed by Infinite Open Source Solutions LLP™
        </a> */}
      </div>
    </div>
  );
}

export default AuthLayout;
