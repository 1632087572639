import React, {useRef} from 'react';
import style from './invoice.module.scss';
import DatePickers from '../common/DateRangePicker';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import MySubscriptionTable from './MySubscriptionTable';

import _ from "lodash";

function MySubscriptionReport(props) {
    const {t} = useTranslation();
    
    return (
        <div className={style.reportContiner}>
            <Card className={style.panel}>
                <Card.Body className={`${style.panelBody} body-card`}>
                    <div>
                        <DatePickers
                        {...props.filter}
                        change={props.changeDate}
                        />
                        <Button  style={{marginLeft:'10px'}} className={`${style.btnPrimary} btn-info-2`} onClick={props.submitFilter} >
                            {t('Button.search')}
                        </Button>
                        <Button  style={{marginLeft:'10px'}} className={`${style.btnInfo} btn-info-2`} onClick={props.resetButton}>
                            {t('Button.reset')}
                        </Button>
                    </div>
                    <div className={style.dataTalbes}>
                        <MySubscriptionTable 
                            {...props.report}
                            paginationchange={props.paginationchange}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default MySubscriptionReport
