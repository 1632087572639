import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './Common.module.scss';

function AddonNotification(props) {
    const {t} = useTranslation();
    return (
        <div className={style.addonNotification}>
            <div className={style.addonDescription}>
                <div>
                    <i className={`fa fa-exclamation-circle bell ${style.icon}`}></i>
                </div>
                <div>
                    <strong>{t('Common.note')}</strong>
                    <div>{t('Common.addon_text')}</div>
                </div>    
            </div>
        </div>
    )
}

export default AddonNotification
