import React,{useEffect}from 'react';
import classNames   from "classnames";
import {useTranslation} from 'react-i18next';
import {useSelector,useDispatch}  from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {changeLang,addLang} from '../../../store/action';
import AppService from '../../../service/common/GetApi'
import {useHistory} from 'react-router-dom';
//service
import AppInfoservice from '../../../service/common/Appinfo';
//styels
import style from './Common.module.scss';
//custum lang dropsown lang button
const LangToggle = React.forwardRef(({ children, onClick }, ref) => (
    
    <span
      className={style.selectedLang}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      {children}
      <span className={style.caretIcon}></span>
    </span>
  )); 

const Language = ({classname,ismainlayout}) => {
    const {i18n}    = useTranslation();
    const Lang      = useSelector(state => state.Lang);//current language
    const dispatch  = useDispatch(); //redux state change
    const history   = useHistory();
    useEffect(()=>{
        const service = new AppInfoservice();
        service.Appinfo().then((res)=>{
            if(res.status){
                if (res.data.lang_status) {
                    dispatch(addLang(res.data.languages));
                }  
            }else{
                if(res.error.code === 1001){
                    history.push('/logout')
                }else if(res.error.code ===1002){
                    history.push('/logout')
                }
            }
        })  
    },[dispatch,history])
    const classes   = classNames(
        classname
    );
        // if (localStorage.getItem('i18nextLng') !== Lang.currentLang) {
        //     i18n.changeLanguage(Lang.currentLang);
        // }
    //language change handler
    function chagelang(lang,id){
        const service = new AppService();
        let idData = {
            lang_id : parseInt(id)
        }
            i18n.changeLanguage(lang);
            service.setLanguage(idData).then(res=>{
                if(res.status){
                    dispatch(changeLang(lang));
                    // i18n.changeLanguage(lang);
                }
            })
    }
    return (
        <div className='login_lang_box'>
        {
            Lang.languages?.length > 0 &&
            <div className={style.langContainer}>
                <Dropdown className={`${classes} ${style.langButton}`}>
                    <Dropdown.Toggle as={LangToggle}>
                        <img src={require('../../../assets/images/flags/'+Lang.currentLang+'.png').default} alt="" className={style.langFlag}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`animate__animated animate__fadeInRight ${style.languageDropDown}`}>
                        {
                            Lang.languages.map((value,index)=>{
                                return <Dropdown.Item key={index} onClick={() => chagelang(value.code,value.id)}>
                                            <img
                                                    src={require('../../../assets/images/flags/'+value.code+'.png').default}
                                                    alt=""
                                                    />
                                            <span className="pl-1">{value.label}</span>
                                        </Dropdown.Item>
                            }) 
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }
        </div>
    );
};

export default Language;