import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomButton } from '../common';
import { useTranslation } from 'react-i18next';
import { PayPalButtons,PayPalScriptProvider } from '@paypal/react-paypal-js'
// import { PayPalButton } from "react-paypal-button-v2";


function PaypalCheckoutButton(props) {
  console.log("Props in paypal checkout button are ", props)
  return (
    <PayPalScriptProvider options = {{ "client-id" : process.env.REACT_APP_PAYPAL_CLIENT_ID }}>

    {/* <div className="p-2"><PayPalButtons /></div> */}

    <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "1.99",
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        alert(`Transaction completed by ${name}`);
                    });
                }}
            />

            

    </PayPalScriptProvider>

  )
}

export default PaypalCheckoutButton