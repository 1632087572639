import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import sponser from "../../../assets/images/dashboard/sponcer.png";
import TileItems from "./TileItems";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";

function Tile(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);

  console.log('props.pvData :: ', props.pvData)
  return (
    <div className={style.dashboard_boxs_listing_sponcer_sec}>
      <Row className="fll_wdth_row_tab">
        <Col lg={12} md={12} sm={12} className={style.mid_flll_wdth}>
          <Row className="mb-3">
            {props.lodervalue && 
              [1, 2, 3, 4].map((items, index) => {
                return (
                    <Col lg='3' md='3' sm='6' className={style.boxes_lst_sec}>
                    <div className={`${style.list_boxes_main}`}>
                      <div
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_dashboard_pannel_box} `
                            : `${style.dashboard_pannel_box}`
                        }`}
                      >
                        <div className={style.listing_box_sec}>
                          <div>
                            <SkeletonTheme
                              baseColor="#202020"
                              highlightColor="#444"
                            >
                              <Skeleton circle={true} height={90} width={90} />
                            </SkeletonTheme>
                          </div>
                          <div className={style.listing_box_head}>
                            <SkeletonTheme
                              baseColor="#202020"
                              highlightColor="#444"
                            >
                              <Skeleton width={100} count={1} />
                            </SkeletonTheme>
                          </div>
                          <div className={style.listing_box_cnout}>
                            <SkeletonTheme
                              baseColor="#202020"
                              highlightColor="#444"
                            >
                              <Skeleton width={50} count={1} />
                            </SkeletonTheme>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            }
            {props.Tiles && props.Tiles.length > 0 && 
              props.Tiles.map((items, index) => {
                return (
                  <Col lg='3' md='3' sm='6' className={style.boxes_lst_sec}>
                    <TileItems
                      indexNo={index}
                      amount={items.amount}
                      text={items.text}
                      to={items.to}
                      key={index}
                      boxname={index}
                      filter={items.filter}
                      filterChange={props.filterChange}
                      lodervalue={props.lodervalue}
                      amount_withcurrency={items.withcurrency}
                    />
                  </Col>
                );
              })
            }
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12} className={`${style.mid_flll_wdth} ${style.mid_flll_wdth_sponsor} `}>
          {props.lodervalue && (
            <div className={style.sponcer_sec}>
             <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                } body-card`}
              >
                <div className={style.sponcer_top_name_sec}>
                  <div className={style.sponcer_top_name_txt}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={170} count={1} />
                      <Skeleton width={110} count={1} />
                    </SkeletonTheme>
                  </div>

                  <div className={style.sponcer_top_ico}>
                    {" "}
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={60} height={80} count={1} />
                    </SkeletonTheme>
                  </div>
                  <div className={style.spouncer_cnt_row}>
                    {[1, 2, 3, 4].map((data, index) => {
                      return (
                        <div className={style.spouncer_cnt_boxes} key={index}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton width={110} count={1} />
                            <Skeleton width={80} count={1} />
                          </SkeletonTheme>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.SponserData && (
            <div className={`${style.sponcer_sec} sponcer-box mb-2 `}>
              <div
                className={`left-to-right body-card ${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                } mb-0  dashboard-bottom-box`} 
              >
                {/* <>
                  {props.SponserData[0].text === "sponsorName" && (
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_sponcer_top_name_sec}`
                          : `${style.sponcer_top_name_sec}`
                      }`}
                    >
                      <div
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_sponcer_top_name_txt}`
                            : `${style.sponcer_top_name_txt}`
                        }`}
                      >
                        <span className="text-white">{t("Common.sponsor")}</span>
                        {props.SponserData[0].head}
                      </div>

                      <div className={style.sponcer_top_ico}>
                        <img
                          src={sponser}
                          alt=""
                          style={{
                            mixBlendMode:
                              currentTheme == "theme-dark" ? "multiply" : "",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className={style.spouncer_cnt_row}>
                    {props.pvData.map((data, index) => {
                      return (
                        <div
                          className={`body-label ${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_spouncer_cnt_boxes}`
                              : `${style.spouncer_cnt_boxes}`
                          }`}
                          key={index}
                        >
                          {t("Common." + data.text)}
                          <strong>{data.head}</strong>
                        </div>
                      );
                    })}
                  </div>
                </> */}
                <Row>
                  <Col lg='12'>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-column gap-1 text-dark">
                        <div className="text-white">{t("Common.sponsor")}</div>
                        <div className="text-white">{props.SponserData[0].head}</div>
                      </div>
                      <div>
                        <img src={sponser} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-start gap-2">
                    <div className={`${style.spouncer_cnt_row} mt-0`}>
                    {
                    props.pvData.map((data, index) => {
                      return (
                        <div
                          className={`body-label ${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_spouncer_cnt_boxes}`
                              : `${style.spouncer_cnt_boxes}`
                          } text-dark`}
                          key={index}
                          style={{width:'20%!important'}}
                        >
                          <span className="text-white">{t("Common." + data.text)}</span>
                          <strong className="text-white">{data.head}</strong>
                        </div>
                      );
                    })
                  }
                  </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Tile;
