import React from 'react';
import style from './invoice.module.scss';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Summary } from './';
import moment from 'moment';

function PrintContent(props) {
    const { t } = useTranslation();

    const renderValue = (value) => value !== null && value !== undefined ? value : '';
    const mobileValue = (value) => value !== null && value !== undefined ? value : 'NA';

    console.log("props",props)

    return (
        <div id="print_area">
            <Card className={style.panel}>
                <Card.Body className={style.panelBody}>
                    <div className={style.logoImg}>
                        {props.companyInfo && (
                            <img src={renderValue(props.companyInfo.login_logo)} alt="" />
                        )}
                    </div>
                    <Row>
                        <Col xs={6}>
                            <h4>{renderValue(props.companyInfo?.company_name)}</h4>
                            <p style={{ color: "white" }}>{renderValue(props.companyInfo?.company_address)}</p>
                            <p>
                                <span>{t('Common.phone')}:</span> <span>{renderValue(props.companyInfo?.phone)}</span><br />
                                <span>{t('profile.email')}:</span> <span>{renderValue(props.companyInfo?.email)}</span>
                            </p>
                        </Col>
                        <Col xs={6} className={`text-right`}>
                            <p style={{ color: "white" }}>#{renderValue(props.invoice_no)}</p>
                            <h5>{props.order_date ? moment(props.order_date).format("D MMM, YYYY") : ''}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <h4>{t('Common.company')}</h4>
                            <div className={`${style.well}`}>
                                <address>
                                    <strong style={{ color: "white" }}>{renderValue(props.companyInfo?.company_name)}</strong><br />
                                    <span>{renderValue(props.companyInfo?.company_address)}</span><br />
                                    <abbr title="Phone" style={{ color: "white" }}>{t('Common.phone')}:</abbr><span style={{ color: "white" }}>{renderValue(props.companyInfo?.phone)}</span>
                                </address>
                                <address>
                                    <strong className='myprofile'>{t('profile.email')}</strong><br />
                                    <span>{renderValue(props.companyInfo?.email)}</span>
                                </address>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <h4>{t('Common.purchaseAddress')}:</h4>
                            <div className={`${style.well}`}>
                                <address>
                                    <strong>{renderValue(props.address?.name)}</strong><br />
                                    <span>{props.address ? `${renderValue(props.address.name)} ${renderValue(props.address.pin)} ${renderValue(props.address.town)}` : ''}</span><br />
                                    <abbr title="Phone" style={{ color: "white" }}>{t('Common.phone')}:  </abbr><span style={{ color: "white" }}>{mobileValue(props.address?.mobile)}</span>
                                </address>
                                <address style={{ visibility: "hidden" }}>
                                    <strong>Email</strong><br />
                                    <span>NA</span>
                                </address>
                            </div>
                        </Col>  
                    </Row>
                    {props.product_details && (
                        <Summary
                            data={props.product_details}
                        />
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

export default PrintContent;
