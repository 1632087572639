import React from 'react';
import style from './invoice.module.scss';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import MyTravelPackageTable from './MyTravelPackageTable';

import _ from "lodash";

function MyTravelPackageReport(props) {
    const {t} = useTranslation();
    
    return (
        <div className={style.reportContiner}>
            <Card className={`${style.panel} bg-transparent`}>
                <Card.Body className={`${style.panelBody} body-card`}>
                    <div className={style.dataTalbes}>
                        <MyTravelPackageTable 
                            {...props.report}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default MyTravelPackageReport
