import React from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
// import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
// import BoardContent from "../../components/network/board/BoardContent";
// import BoardService from "../../../service/tree/board";
import companyName from "../../../store/mobxStore/companyName";
// import Tree from 'react-d3-tree';
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";

const DthreeView = (props) => {
  const { t } = props;
  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };
  const StyledNode = styled.div`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid red;
  `;
  return (
    <div>
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.boardView")}
          </title>
        </Helmet>
        <PageTitle title="boardView" buttonOn={false} />
        <div style={{ justifyContent: "center" }}>
          <Tree
            label="Root"
            lineBorderRadius="10px"
            lineColor="#c8d5d8"
            lineHeight="9px"
            lineWidth="2px"
          >
            <TreeNode label={<StyledNode>Child 1</StyledNode>}>
              <TreeNode label={<StyledNode>Grand Child</StyledNode>} />
            </TreeNode>
            <TreeNode label={<div>Child 2</div>}>
              <TreeNode label={<div>Grand Child</div>}>
                <TreeNode label={<div>Great Grand Child 1</div>} />
                <TreeNode label={<div>Great Grand Child 2</div>} />
              </TreeNode>
            </TreeNode>
            <TreeNode label={<div>Child 3</div>}>
              <TreeNode label={<div>Grand Child 1</div>} />
              <TreeNode label={<div>Grand Child 2</div>} />
            </TreeNode>
          </Tree>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(DthreeView));

// didnt done with previous state (state update)
