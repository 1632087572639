import React, { useState } from "react";
import style from "./tree.module.scss";
import TreeViewService from "../../../service/tree/treeView";
import { useHistory } from "react-router-dom";
import { ToolTips } from "./";
import { usePopperTooltip } from "react-popper-tooltip";
import { useSelector } from "react-redux";

function TreeViewNode(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new TreeViewService();
  const history = useHistory();
  const [state, setState] = useState({
    expand: false,
    data: [],
    tooltip_array: [],
  });
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  //click the expand button click
  const expandData = () => {
    if (state.data.length === 0) {
      service.getTreeViewData(props.id).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            expand: true,
            data: res.data.data,
            tooltip_array: res.data.tooltip_array,
          }));
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else {
          }
        }
      });
    } else {
      setState((prev) => ({
        ...prev,
        expand: true,
      }));
    }
  };

  //close expand
  const closeExpand = () => {
    setState((prev) => ({
      ...prev,
      expand: false,
    }));
  };
  //tooltip index
  const getToolTipIndex = (element, id) => element.user_name === id;
  return (
    <li
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_listGroupItem} `
          : `${style.listGroupItem}`
      }`}
    >
      <span className={`${style.expand}`}>
        {props.child && [
          !state.expand ? (
            <i
              className="fa fa-plus-circle"
              onClick={expandData}
              key={state.expand}
            ></i>
          ) : (
            <i
              className="fa fa-minus-circle"
              onClick={closeExpand}
              key={state.expand}
            ></i>
          ),
        ]}
      </span>
      <div className={`${style.commentCard}`}>
        <figure
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_figure} `
              : `${style.figure}`
          }`}
        >
          <img src={props.image} className={`${style.image}`} alt="" />
          <figcaption className={`${style.figcaption}`}>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_username} `
                  : `${style.username}`
              }`}
            >
              {props.title}
            </div>
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_fullname} `
                  : `${style.fullname}`
              }`}>{props.full_name}</div>
          </figcaption>
          <figcaption className={`${
              currentTheme == "theme-dark"
                ? `${style.figcaption} ${style.dark_mode_userLevel}`
                : `${style.figcaption} ${style.userLevel}`
            } second-caption`}>
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_level} `
                  : `${style.level}`
              }`}>{props.level}</div>
            <div className={`${style.level_text}`}>level</div>
          </figcaption>
          <figcaption
            className={`${
                currentTheme == "theme-dark"
                  ? `${style.figcaption} ${style.dark_mode_tooltipInfo} ${style.help} `
                  : `${style.figcaption} ${style.tooltipInfo} ${style.help} `
              } last-caption`}
            ref={setTriggerRef}
          >
            <div className={style.help}>
              <i className="fa fa-info"></i>
            </div>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: `tooltip-container ${style.tooltipContainer}`,
                })}
              >
                <div {...getArrowProps({ className: `${style.arrow}` })} />
                <ToolTips config={props.toolTipConfig} {...props} />
              </div>
            )}
          </figcaption>
        </figure>
      </div>
      {state.expand && (
        <ul>
          {state.data.map((item, index) => {
            const toolTip = state.tooltip_array.findIndex((element) => {
              return getToolTipIndex(element, item.id);
            });
            return (
              <TreeViewNode
                key={index}
                toolTipConfig={props.toolTipConfig}
                {...state.tooltip_array[toolTip]}
                {...item}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
}

export default TreeViewNode;
