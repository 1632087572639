import React from "react";
import style from "./wizard.module.scss";
import { useSelector } from "react-redux";

function SmartWizard(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <section className={style.formBox}>
      <div className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_formWizard}`
            : `${style.formWizard}`
        } bg-transparent`}>
        <div className={style.formWizardSteps}>
          {props.steps &&
            props.steps.length > 0 &&
            props.steps.map((step, index) => (
              <div
                className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_step}  ${
                          props.activeTab === index && style.active
                        } ${index < props.activeTab && style.activated}`
                      : `${style.step}  ${
                          props.activeTab === index && style.active
                        } ${index < props.activeTab && style.activated}`
                  }`}
                key={index}
              >
                <p>{step.name}</p>
              </div>
            ))}
        </div>
        <div className={style.fieldSet}>
          {props.steps &&
            props.steps.length > 0 &&
            props.steps.map((item, index) => {
              if (props.activeTab === index) {
                return <item.Components key={index} {...item.props} />;
              }
              return false;
            })}
        </div>
      </div>
    </section>
  );
}

export default SmartWizard;
