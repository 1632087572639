import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import TravelContent from '../../components/travelPackage/TravelContent';
import Spinner from '../../shared/Spinner';
import travelPackageService from '../../../service/travelpackage';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';

function TravelPackage(props) {
    let travel = new travelPackageService();
    let history = useHistory();

    const [state, setState] = useState({
        data: [],
        loader: true,
        alert: {
            show: false,
            varient: '',
            message: ''
        },
        cart: []

    })
    const {id} = props.match.params;

    useEffect(() => {
        travel.getTravelPackageList(id)
            .then(res => {
                setState(prevState => ({
                    ...prevState,
                    data: res.data,
                    loader: false
                }))
            })
    }, [id]);
    
    // FUNCTION FOR GET PAYPAL LINK FOR PAY
    const purchaseTravelPackage = (package_id = 0) => {
        travel.getPaymentLink(package_id)
        .then(url => {
            console.log('url :: ', url)
            if (url?.length > 0) {
                // alert('your order created');
                // history.push('mytravelpackages');
                window.location.href = url;
            }
        })
    }
    
    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | Travel Package</title>
            </Helmet>
            <PageTitle
                title="Travel Packages"
                isUpdate={true}
            />
            <TravelContent
                {...state}
                onclick={purchaseTravelPackage}
            />
        </div>
    );
}
export default withTranslation()(withRouter(TravelPackage));