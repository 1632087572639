import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useParams, useHistory } from 'react-router-dom';
import PaymentView from '../../../service/payment_view/PaymentView';


function SeethepaymentFail(props) {    

    return (
        <div className="h-500" style={{"marginTop" : "10%"}}>
           <center><h2>Payment failed </h2></center><br/><br/>
           <center><h3>Please go to Shopping/Subscription cart for further purchase </h3></center>
        </div>
    );


}
export default withTranslation()(withRouter(SeethepaymentFail));