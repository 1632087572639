import React, { useEffect, useState } from 'react';
import style from './tools.module.scss';
import {Alert, Card,Accordion} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import FaqService from '../../../service/tools/faqs';
import {useHistory} from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName'
import Spinner from '../../shared/Spinner';


const FaqContent = () => {
    const {t} = useTranslation();
    const [state,setState] = useState([]);
    const [loading,setLoading] = useState(false);
    const history = useHistory();
    const [show, setShow] = useState(true);
    useEffect(()=>{
        const service = new FaqService();
        setLoading(true)
        service.getNews().then(res=>{
            if(res.status){

                setState(res.data.faq)
                setLoading(false)
            }else{
                history.push('/logout')
            }
        })
    },[history])

    const accordinClick=(item)=>{
      let data = state.map((items,index)=>{
        
          if(items.id==item.id){
              if(item['active']){
                  return {
                      ...items,
                      active:false
                  }
              }
              else if (!item['active']){
                  return {
                      ...items,
                      active:true

                  }
              }
              else{
                  return {
                      ...items,
                      active:true,
                  }
              }
           
          }
          else{
            return {
                ...items,
                active:false
            }

          }
        })

    setState(data);
    }

    return (  
    loading?
    <Spinner/>:
    

        <div className={style.FaqContainer}>
            {show &&
            <Alert dismissible variant="warning" onClose={() => setShow(false)} dismissible className='body-card' >
                   <span dangerouslySetInnerHTML={{__html : t('Tools.FaqMessage',{name : companyName.companyName})}} style={{color:'green'}}></span>
            </Alert>
         }
            {
                state.map((items,index)=>(
                    <Card key={index} className='mb-4 body-card' >
                        <Card.Body className={style.FaqContent}>
                            <Accordion style={{backgroundColor: '#f6f8f8',padding: 10}}> 
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=>accordinClick(items)}>
                                    <div className={style.accordain_icons}>
                                       <i className={items.active?`fa fa-angle-down`:`fa fa-angle-left`}></i>
                                        </div>
                                        <div className={style.panelHeadingWrapper} >
                                        {items.order}. {items.question}
                                        </div>
                                    </Accordion.Toggle>                              
                                    <Accordion.Collapse eventKey="0" className={`accordian`}>
                                        <Card.Body className={style.mailMessage}  >
                                            {items.answer}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Card.Body>
                    </Card>
                ))
            }
        </div>
    );
}
 
export default FaqContent;