import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import style from "./cart.module.scss";
import { useTranslation } from "react-i18next";
import { FormInputControl, AlertBs } from "../common";
import { Form } from "reactstrap";
import { ConstructionOutlined } from "@mui/icons-material";
const FieldSet = [
  {
    code: "name",
    value: "",
    type: "text",
    required: true,
    fieldname: "name",
    minLength: 3,
    maxLength: 32,
    regex: "^[a-zA-Z]*$",
  },
  {
    code: "address",
    value: "",
    type: "text",
    required: true,
    fieldname: "address",
    minLength: 3,
    maxLength: 32,
    regex: "",
  },
  {
    code: "zipCode",
    value: "",
    type: "text",
    required: true,
    fieldname: "zip_code",
    minLength: 3,
    maxLength: 10,
    regex: "^[0-9-+]+$",
  },
  {
    code: "city",
    value: "",
    type: "text",
    required: true,
    fieldname: "city",
    minLength: 3,
    maxLength: 32,
    regex: "",
  },
  {
    code: "phoneNumber",
    value: "",
    type: "text",
    required: true,
    fieldname: "phone",
    minLength: 5,
    maxLength: 10,
    regex: "^[0-9]{10,15}$",
  },
];
function AddModal(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    close: true,
    field: FieldSet,
    alert: {
      show: false,
      variant: "",
      message: "",
    },
  });

  //change handler
  const changeHandler = (event) => {
    const { name, value } = event.target;
    const TempData = state.field.map((item) => {
      
      if (item.code === name) {
        return {
          ...item,
          value: value,
          error: null,
        };
      }
      return {
        ...item,
      };
    });
    setState((prev) => ({
      ...prev,
      field: TempData,
    }));
  };

  // onSubmit form
  const submit = (e) => {
    e.preventDefault();
    let isvalid = true;
    setState((prevState) => ({
      ...prevState,
      close: true,
    }));
    const validate = state.field.map((item, index) => {
      console.log("regex",item)
      console.log(item.maxLength != "");
      if (!item.value && item.required) {
        isvalid = false;
        return {
          ...item,
          error: {
            error: "validation.required",
            field: {
              field: item.code,
            },
          },
        };
      }

     if (item.maxLength != "") {
        if (item.value.length > item.maxLength) {
          isvalid = false;
          return {
            ...item,
            error: {
              error: "validation.max_length",
              field: {
                field: item.code,
                length:item.maxLength
              },
            },
          };
        }
      }
  if (item.minLength != "") {
        if (item.value.length < item.minLength) {
          isvalid = false;
          return {
            ...item,
            error: {
              error: "validation.min_length",
              field: {
                field: item.code,
                length:item.minLength
              },
            },
          };
        }
      }  if (item.regex != "") {
        const regex = new RegExp(item.regex);
        console.log("sdfcsfsdf",regex)
        console.log(regex.test(item.value),item.name)
        if (!regex.test(item.value)) {
          isvalid = false;
          return {
            ...item,
            error: {
              error: "validation.invalid",
              field: {
                field: item.code,
              },
            },
          };
        }
      }
      else {
        
      }

      return {
        ...item,
      };
    });

    if (isvalid) {
      const formdata = {};
      state.field.map((item) => {
        formdata[item.fieldname] = item.value;
        return true;
      });
      props.submit(formdata);
      setState((prev) => ({
        ...prev,
        field: FieldSet,
        alert: {
          show: false,
          variant: "",
          message: "",
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        field: validate,
        alert: {
          show: true,
          message: "validation.checkValueYouHaveSubmitted",
          variant: "danger",
        },
      }));
    }
  };
  //close alert message
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        variant: "",
      },
    }));
  };
  const closeHandle = () => {
    props.handleClose();

    setState((prevState) => ({
      ...prevState,
      close: false,
      field: FieldSet,
      alert: {
        show: false,
        variant: "",
        message: "",
      },
    }));
  };

  return (
    <Modal
      show={props.show}
      onHide={closeHandle}
      contentClassName={style.modalContent}
      className={`${style.addAddressModal} animate__animated animate__fadeInDown  animate__faster	5s`}
    >
      <Form onSubmit={submit}>
        <Modal.Header closeButton className={style.modalHeader}>
          <Modal.Title>{t("Common.enterNewShippingAddress")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={style.addressCardBody}>
          <AlertBs
            show={state.alert.show}
            varient={state.alert.variant}
            message={state.alert.message}
            close={closeAlert}
          />
          {state.field.map((item, index) => {
            return (
              <div key={index} className="mb-3">
                <FormInputControl
                  label={t(`Common.${item.code}`)}
                  required={item.required}
                  error={item.error}
                  inputProps={{
                    name: item.code,
                    type: item.type,
                    value: item.value,
                    onChange: (e) => {
                      changeHandler(e, index);
                    },
                  }}
                />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className={style.modalFooter}>
          <Button
            className={`${style.btnAddon} ${style.btnPrimary}`}
            type="submit"
          >
            {t("Button.saveAddress")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddModal;
