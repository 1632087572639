import PayPalPaymentButton from "../../../components/paypalButton"

function CC_PaypalPayment(props) {

    return (
        <div className="h-100">
            <div className='m-2' style={{backgroundColor:'#ffffff', minHeight:'250px'}}>
                <div className='paypal-button-section'>
                    <PayPalPaymentButton />
                    {/* <PayPalScriptProvider options={{ "client-id": "AZ-lKlbZ5Jftydr-x_rIW5_su_0FSMHn3uEj8UmFdIPuCMN1UG5PGwnx0h53wlfWpDE8q-stqm9sQcuh" }}>
                        <PayPalButtons style={{ layout: "horizontal" }} />
                    </PayPalScriptProvider> */}
                </div>
            </div>
        </div>
    )

}
export default CC_PaypalPayment