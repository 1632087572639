import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import style from "./registration.module.scss";
import { useHistory } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  SponsorPackage,
  ContactInformation,
  LoginInformation,
  TAndC,
  Payment,
} from "./";
import ReplicaRegService from "../../../service/Auth/Replicareg";
import ProfileService from "../../../service/profile";
import { PasswordValidation } from "../../hook/passwordValidation";
import { useParams } from "react-router-dom";

function Content(props) {
  console.log("propsrinku:===>", props);
  const { t } = useTranslation();
  let history = useHistory();
  let params = useParams();
  const [password, setPassword] = useState({
    password: "",
    policy: "",
  });
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    PasswordValidation({
      password: password.password,
      policy: password.policy,
    });
  //service class object
  const servcie = new ReplicaRegService();
  const [termsAndCond, setTermsAndCond] = useState({
    show: false,
    content: "",
  });

  //wizard
  const [page, setPage] = useState(0);
  const [steps, setSteps] = useState([
    { title: "" },
    { title: "" },
    { title: "" },
  ]);

  //current payment method
  const [payment, setPayment] = useState("freejoin");
  useEffect(() => {
    if (props.data.PaymentMethodsStatus) {
      setSteps([{ title: "" }, { title: "" }, { title: "" }, { title: "" }]);
    }
  }, [props]);

  //wizard pages
  const nextPage = () => {
    // e.preventDefault()
    setPage(page + 1);
  };

  //ewallet data
  const [ewalletData, setEwalletData] = useState({
    username: "",
    password: "",
  });
  //use state create the data
  const [state, setState] = useState({
    sponsor: props.data.sponsor.fields,
    contactInfo: props.data.contactInfo.fields,
    LoginInfo: props.data.loginInformation.fields,
    PaymentMethods: props.data.paymentMethods,
    amount: 0,
    userName: "",
    product: "",
    submitError: false,
    errorMessage: "",
    paypal: {
      orderId: "",
      payeerId: "",
    },
  });
  const [selection, setSelection] = useState();
  const [passwordPolicy, setPasswordPolicy] = useState({
    uppercase: upperCase,
    lowercase: lowerCase,
    sp_char: specialChar,
    number: hasNumber,
    min_length: validLength,
  });
  useEffect(() => {
    setPasswordPolicy({
      uppercase: upperCase,
      lowercase: lowerCase,
      sp_char: specialChar,
      number: hasNumber,
      min_length: validLength,
    });
  }, [validLength, hasNumber, upperCase, lowerCase, specialChar]);

  //preve button click
  const prevPage = () => {
    setPage(page - 1);
  };

  //change fields
  const ChangeHandler = (e, key) => {
    const { name, value } = e.target;
    const newData = state[key].map((items) => {
      if (items.code === name) {
        if (name === "country") {
          const countryService = new ProfileService();
          countryService.countryChange(value).then((res) => {
            if (res.status) {
              const newOptions = state.contactInfo.map((contactItems) => {
                if (contactItems.code === "state") {
                  return {
                    ...contactItems,
                    options: res.data,
                  };
                }
                return contactItems;
              });
              setState((prev) => ({
                ...prev,
                contactInfo: newOptions,
              }));
            } else {
              if (res.error.code === 1002) {
                history.push("/logout");
              }
            }
          });
        } else if (items.code === "password") {
          setPassword({
            ...password,
            password: value,
            policy: items.validation,
          });
        } else if (items.code === "agree_terms") {
          let tempvalue = value === "false" ? false : true;
          return {
            ...items,
            value: !tempvalue,
            error: "",
            errorField: {
              field: "",
            },
          };
        }
        return {
          ...items,
          value: value,
          error: "",
          errorField: {
            field: "",
          },
        };
      } else return items;
    });
    setState((prev) => ({
      ...prev,
      [key]: newData,
    }));
  };

  let legValid = false;
  ///form submitting and form validation
  const submitForm = async (e, id) => {
    let isValid = true;
    let username = "";

    e.preventDefault();
    if (id !== "payment") {
      const newData = await Promise.all(
        state[id].map(async (items) => {
          if (!items.value && items.required) {
            isValid = false;
            let errorname = "required";
            if (items.type === "select") {
              errorname = "selectRequired";
            } else if (items.code === "agree_terms") {
              errorname = "termsCondietions";
            }
            return {
              ...items,
              error: "validation." + errorname,
              errorField: {
                field: items.code,
              },
            };
          } else if (items.code === "position") {
            await checkLeg(items, id);
            if (!legValid) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalid",
                errorField: {
                  field: "position",
                },
              };
            }
          } else if (items.code === "email") {
            if (!emailVaidation(items.value)) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalidEmail",
              };
            }
          } else if (items.code === "userName") {
            username = items.value;
            if (items.value.length < items.validation.min_length) {
              isValid = false;
              return {
                ...items,
                error: "validation.min_length",
                errorField: {
                  field: "userName",
                  length: items.validation.min_length,
                },
              };
            } else if (items.value.length > items.validation.max_length) {
              isValid = false;
              return {
                ...items,
                error: "validation.max_length",
                errorField: {
                  field: "userName",
                  length: items.validation.max_length,
                },
              };
            } else if (!checkUsername(items.value)) {
              isValid = false;
              return {
                ...items,
                error: "validation.userNameUnavailable",
              };
            } else if (items.error) {
              isValid = false;
            }
          } else if (items.code === "confirmPassword") {
            let password = "";
            state.LoginInfo.some((element, index, arr) => {
              password = arr[index].value;
              return element.code === "password";
            });
            if (password !== items.value) {
              isValid = false;
              return {
                ...items,
                error: "validation.mismatch",
              };
            }
          } else if (items.code === "dateOfBirth") {
            let Now = moment().format("YYYY-MM-DD");

            if (
              moment(Now).diff(items.value, "years") <
              parseInt(items.validation.agelimit)
            ) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalidAgelimit",
                errorField: {
                  age: items.validation.agelimit,
                },
              };
            }
          } else if (items.code === "product") {
            let packages = "";
            let amount = 0;
            state.sponsor.some((element, index, arr) => {
              packages = arr[index];
              return element.code === "product";
            });
            packages.options.some((element, index, arr) => {
              amount = parseInt(arr[index].productValue);
              return element.value === packages.value;
            });
            setState((prev) => ({
              ...prev,
              amount: amount,
              product: items.value,
            }));
          }
          return items;
        })
      );
      setState((prev) => ({
        ...prev,
        [id]: newData,
        userName: username,
      }));
    }
    if (isValid) {
      if (page === steps.length - 1) {
        let formData = {};
        for (const [key] of Object.entries(state)) {
          if (["sponsor", "contactInfo", "LoginInfo"].includes(key)) {
            state[key].map((items) => {
              formData[items.field_name] = items.value;
              return false;
            });
          }
        }
        formData.payment_method = payment;
        if (payment === "ewallet") {
          formData.user_name_ewallet = ewalletData.username;
          formData.tran_pass_ewallet = ewalletData.password;
        } else if (payment === "epin") {
          formData.pin_array = state.pinArray.length;
          state.pinArray.forEach((element, index) => {
            formData["epin" + (index + 1)] = element.pin;
          });
        } else if (payment === "paypal") {
          formData.paypal_token = state.paypal.orderId;
          formData.PayerID = state.paypal.payeerId;
          formData.currency = "USD";
        }
        if (params.leg) {
          formData["position"] = params.leg;
        }
        if (params.reg_from_tree) {
          formData["reg_from_tree"] = true;
        }
        // return false
        // formData.placement_user_name = '';
        servcie.regsiterSubmit(formData).then((res) => {
          console.log(formData);
          if (res.status) {
            console.log("res :: 2102 :: ", res);
            alert(`Complete your registration with email Verification`);
            history.push("/login");
            // history.push({
            //   pathname: '/replica_preview/'+res.data.userName,
            //   data : {
            //     message : res.data.code,
            //     username : res.data.userName
            //   }
            // })
          } else {
            console.log(res);
            //validation error
            let errorKey = "";
            let errorMessage = "";
            if (res.error.code === 1004) {
              Object.keys(res.error.fields).map(function (key, index) {
                if (key.substr(key.length - 4) !== "_err") {
                  Object.keys(state).map((stateitems, index) => {
                    if (
                      ["sponsor", "contactInfo", "LoginInfo"].includes(
                        stateitems
                      )
                    ) {
                      let data = state[stateitems].map((items) => {
                        if (items.field_name === key) {
                          if (!errorKey) {
                            errorKey = stateitems;
                            errorMessage = res.error.fields[key + "_err"];
                          }
                          return {
                            ...items,
                            error: t(
                              "validation." + res.error.fields[key + "_err"]
                            ),
                          };
                        }
                        return items;
                      });
                      setState((prev) => ({
                        ...prev,
                        [stateitems]: data,
                      }));
                    }

                    return false;
                  });
                }
                return false;
              });
            } else if (res.error.code === 1006) {
              errorMessage = "registrationBlocked";
            } else if (res.error.code === 1034) {
              errorMessage = "registrationNotAllowed";
            } else if (res.error.code === 1035) {
              errorMessage = "invalidSponsorUsername";
            } else if (res.error.code === 1036) {
              errorMessage = "invalidPaymentMethod";
            } else if (res.error.code === 1016) {
              errorMessage = "invalidEpin";
            } else if (res.error.code === 1015) {
              errorMessage = "invalidTransactionDetails";
            } else if (res.error.code === 1011) {
              errorMessage = "userNameUnavailable";
            } else if (res.error.code === 1002) {
              history.push("/logout");
            }
            setState((prev) => ({
              ...prev,
              errorMessage: t("validation." + errorMessage),
              submitError: true,
            }));

            //redierect the wizard page wizard
            switch (errorKey) {
              case "sponsor":
                setPage(0);
                break;
              case "contactInfo":
                setPage(1);
                break;
              case "LoginInfo":
                setPage(2);
                break;
              default:
                break;
            }
          }
        });
      } else {
        nextPage();
      }
    }
  };

  //blur handler
  const blurhandler = async (key) => {
    if (key === "password") {
      let Policyvalid = true;
      let NewData = state.LoginInfo.map((items) => {
        if (items.code === "password") {
          Object.keys(items.validation).map(function (key, index) {
            if (key !== "disableHelper") {
              if (!passwordPolicy[key]) {
                Policyvalid = false;
              }
            }
            return false;
          });
          if (!Policyvalid) {
            return {
              ...items,
              error: "validation.passwordPolicyInvalid",
            };
          }
        }
        return items;
      });
      setState((prev) => ({
        ...prev,
        LoginInfo: NewData,
      }));
    } else if (key === "sponsorUserName") {
      let sponsorData = "";
      state.sponsor.some((element, index, arr) => {
        sponsorData = arr[index];
        return element.code === "sponsorUserName";
      });
      const formData = {
        username: sponsorData.value,
      };
      servcie.validateSponsorUserName(formData).then((res) => {
        if (res.status) {
          const newData = state.sponsor.map((items) => {
            if (items.code === "sponsorFullName") {
              return {
                ...items,
                value: res.data.sponsorFullName,
              };
            }
            return items;
          });
          setState((prev) => ({
            ...prev,
            sponsor: newData,
          }));
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else {
            const newData = state.sponsor.map((items) => {
              if (items.code === "sponsorUserName") {
                return {
                  ...items,
                  error: "validation.invalid",
                  errorField: {
                    field: "sponsorUserName",
                  },
                };
              } else if (items.code === "sponsorFullName") {
                return {
                  ...items,
                  value: "",
                };
              }
              return items;
            });
            setState((prev) => ({
              ...prev,
              sponsor: newData,
            }));
          }
        }
      });
    } else if (key === "userName") {
      let username = "";
      state.LoginInfo.some((element, index, arr) => {
        username = arr[index].value;
        return element.code === "userName";
      });
      if (!username) {
        let NewData = state.LoginInfo.map((items) => {
          if (items.code === "userName") {
            return {
              ...items,
              error: "validation.required",
              errorField: {
                field: "userName",
              },
            };
          }
          return items;
        });
        setState((prev) => ({
          ...prev,
          LoginInfo: NewData,
        }));
      } else if (!(await checkUsername(username))) {
        let NewData = state.LoginInfo.map((items) => {
          if (items.code === "userName") {
            return {
              ...items,
              error: "validation.userNameUnavailable",
            };
          }
          return items;
        });
        setState((prev) => ({
          ...prev,
          LoginInfo: NewData,
        }));
      }
    }
  };
  //check the username
  const checkUsername = async (username) => {
    let formData = {
      userName: username,
    };
    let usernameValid = false;
    await servcie.checkUsename(formData).then((res) => {
      if (!res.status) {
        if (res.error.code === 1002) {
          history.push("/logout");
        }
        usernameValid = false;
        // return false;
      } else {
        usernameValid = true;
      }
    });
    return usernameValid;
  };
  //check the leg availabiliitycontactInformation
  const checkLeg = async (data, id) => {
    let placement = state[id].find(
      (element) => element.code === "placementUserName"
    );
    let sponsorData = "";
    state.sponsor.some((element, index, arr) => {
      sponsorData = arr[index];
      return element.code === "sponsorUserName";
    });
    if (sponsorData.value) {
      const formData = {
        sponsor_leg: data.value,
        sponsor_user_name: sponsorData.value,
        placement_user_name: "",
      };
      if (placement) {
        formData.placement_user_name = placement.value;
      }
      await servcie.checklegAvailability(formData).then((res) => {
        if (!res.status) {
          if (res.error.code === 1033) {
            return (legValid = false);
          } else if (res.error.code === 1002) {
            history.push("/logout");
          }
        }
        return (legValid = true);
      });
    }
  };
  //close handler terms and condietion modal
  const handlemodal = (content = "") => {
    setTermsAndCond((prev) => ({
      ...prev,
      show: !prev.show,
      content: content,
    }));
  };
  //validate email address
  const emailVaidation = (mail) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  //date select change
  const selectDate = (selectedDate, id) => {
    let NewData = state.contactInfo.map((items) => {
      if (items.code === "dateOfBirth") {
        return {
          ...items,
          value: selectedDate,
        };
      }
      return items;
    });
    setState((prev) => ({
      ...prev,
      contactInfo: NewData,
    }));
  };
  //change the payment method change
  const paymentMethodChange = (method) => {
    setPayment(state.PaymentMethods.fields[method].code);
  };

  // get the ewallet data
  const getEwalletData = (username, password) => {
    setEwalletData({
      username: username,
      password: password,
    });
  };

  //get Epin Data
  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prev) => ({
        ...prev,
        pinArray: pinArray,
      }));
    }
  };

  //get the paypal data
  const paypalData = (data) => {
    if (data) {
      setState((prev) => ({
        ...prev,
        paypal: {
          ...prev.paypal,
          orderId: data.orderID,
          payeerId: data.payerID,
        },
      }));
    }
  };

  // const isLogged = JSON.parse(localStorage.getItem("login"));

  // console.log("isLogged", isLogged);

  // useEffect(() => {
  //   if (isLogged && isLogged.token) {
  //     history.push("/dashboard");
  //   }
  // }, [isLogged, history]);
  return (
    <>
      {/* {!isLogged || !isLogged.token ? ( */}
        <div className={style.mainContent}>
          <Row>
            <Col md={{ span: 7, offset: 2 }}>
              <Stepper
                steps={steps}
                activeStep={page}
                lineMarginOffset={0}
                size={40}
                stepsBorderRadius="50%"
                barHeight={5}
              />
              <Alert variant="danger" dismissible show={state.submitError}>
                {state.errorMessage}
              </Alert>
              {page === 0 && (
                <SponsorPackage
                  submit={submitForm}
                  data={state.sponsor}
                  ChangeHandler={ChangeHandler}
                  title={props.data.sponsor.title}
                  blurhandler={blurhandler}
                />
              )}
              {page === 1 && (
                <ContactInformation
                  submit={submitForm}
                  data={state.contactInfo}
                  ChangeHandler={ChangeHandler}
                  prevButtonClick={prevPage}
                  selectDate={selectDate}
                />
              )}
              {page === 2 && (
                <LoginInformation
                  submit={submitForm}
                  data={state.LoginInfo}
                  ChangeHandler={ChangeHandler}
                  prevButtonClick={prevPage}
                  openModal={handlemodal}
                  policy={passwordPolicy}
                  blurhandler={blurhandler}
                  enableFinish={page === steps.length - 1}
                />
              )}
              {page === 3 && (
                <Payment
                  productAmount={state.amount}
                  data={state.PaymentMethods}
                  prevButtonClick={prevPage}
                  submit={submitForm}
                  paymentMethodChange={paymentMethodChange}
                  userName={state.userName}
                  product={state.product}
                  ewallet={getEwalletData}
                  epin={getEpinData}
                  getPaypalData={paypalData}
                  payment_type="registration"
                />
              )}
            </Col>
          </Row>
          <TAndC data={termsAndCond} handleClose={handlemodal} />
        </div>
   
    </>
  );
}

export default Content;
