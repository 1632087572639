import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import style from "./tools.module.scss";
import DownloadService from "../../../service/tools/download";
import { useHistory } from "react-router-dom";
import { BASE } from "../../../configuration/apiconfig";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { height } from "@mui/system";
import newStyle from "../../components/auth/login_page.module.scss"

const DownloadContent = () => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const history = useHistory();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalValue, setModalValue] = useState("")
  const [mediaType, setMediaType] = useState(-1)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    const service = new DownloadService();
    setLoading(true);
    service.getDocument().then((res) => {
      if (res.status) {
        setState(res.data.documen_data);
        console.log('sss ::: ', res?.data?.documen_data)
        setLoading(false);
      } else {
        setLoading(false);
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1057) {
          history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  }, [history]);
  
  const download = (e) => {
    console.log(e);
    var fn = e.match(/([^/])+/g);
    fn = fn[fn.length - 1];
    fetch(e).then((response) => {
      console.log("--------", response);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fn;
        a.click();
      });
    });
  };
  
  // script by sridix start ---
  const openModal = (url = '', catId = -1) => {
    setModalVisible(!modalVisible)

    if (Number(catId) == 1) {
      setModalValue((<div className={'col-md-12 col-sm-10 col-xs-12'}>
      <embed src={url} style={{width:'100%',height:'325px'}} />
    </div>))
    }
    if (Number(catId) == 2) {
      setModalValue((<div className="col-md-12 col-sm-8 col-xs-12" style={{textAlign:'center'}}>
        <div class={style.img_view_section}>
          <div className={style.img_div}>
            <img
              src={url}
              style={{width:'inherit',height:'inherit'}}
            />
          </div>
        </div>
      </div>))
    }
    if (Number(catId) == 3) {
      setModalValue((<div className={'col-md-12 col-sm-9 col-xs-12'}>
        <Iframe
          url={url}
          styles={{width:'100%',height:'325px'}}
          width="100%"
          height="100%"
          display="initial"
          position="relative"
          
        />
      </div>))
    }
  }
  // script by sridix close ---

  const docIcon = (id) => {
    if (id === "1") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/document.svg"}
          alt=""
        />
      );
    } else if (id === "2") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/image.svg"}
          alt=""
        />
      );
    } else if (id === "3") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/mov.svg"}
          alt=""
        />
      );
    }
  };
  return loading ? (
    <Spinner />
  ) : (
    <div>
      <div className={style.downloadConatiner}>
        {state.map((items, index) => (
          <div className={`${style?.mediaContent} body-card border-0`} key={`main-${index}`}>
            <div className={style?.mediaContentTitle}>
              <h5 className={style?.title} style={{color:`${currentTheme == "theme-dark" ? 'white' : 'black'}!important`}}>{items?.title}</h5>
            </div>
            <Row className={style.userDowmloadSection}>
            {
              items?.data?.map((childItem, childIndex) => (
                <Col lg='4' md='4' sm='6' xs='12'>
                  <div className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_content}`
                      : `${style.content}`
                  } material-card`} key={`main-${index}-${childIndex}`}>
                    <div className={style?.dataContent}>
                      <div className={style?.mediabox}>
                        <div className={style?.mediaDiv}>{docIcon(childItem?.ctgry)}</div>
                      </div>
                      <div className={style?.block}>
                        <div className={style?.topBlock} >
                            <div className={style.mTitle}>{childItem?.file_title}</div>
                            <div className={style.material_entry_time_1}>{childItem?.uploaded_date}</div>
                            <div className={style.mDescription}>{childItem?.doc_desc}</div>
                        </div>
                        <div className={style?.bottomBlock}>
                          <div>
                            <div className={style.material_entry_time_2}>{childItem?.uploaded_date}</div>
                          </div>
                          <div>
                              <Button
                              variant="info-2"
                              onClick={(e) => openModal(childItem?.doc_file_name, childItem?.ctgry)}
                              >
                                {/* <a download="foo.txt" target="_blank" href="http://example.org/assets/avatar.png">download</a> */}
                                {/* <a
                                  href='javascript:void(0);'
                                  // target="_blank"
                                  className={style.linkcoloor}
                                  // download
                                  // onClick={(e) => download(childItem?.doc_file_name)}
                                  
                                > */}
                                  <i className="fa fa-eye" />
                                  view
                                {/* </a> */}
                              </Button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            }
            </Row>
            <Row className="justify-content-center">
            {
              items?.data?.length <= 0 && (
                <div className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_content}`
                    : `${style.content}`
                }`} key={index}>
                  <div>
                    <h4>{`No ${items?.title} Available`}</h4>
                  </div>
                </div>
              )
            }
            </Row>
          </div>
        ))}
      </div>
      <Modal className="modal material-modal" size={`${mediaType !== 1 ? 'md' : 'lg'}`} show={modalVisible} backdrop="static" keyboard={false}>
        <ModalHeader> {/* style={{background:'white'}} */}
            <h5>Material Modal</h5>
        </ModalHeader>
        <ModalBody> {/* style={{background:'white'}} */}
            <div>
              <div>
                <Row>
                  <Col lg='12' md='12' sm='9' xs='12'>
                    <div className="row" style={{width:'100%', justifyContent:'center', display:'flex', padding:'10px'}}>{modalValue}</div>
                  </Col>
                </Row>
              </div>
            </div>
        </ModalBody>
        <ModalFooter className="text-end"> {/* style={{background:'white',textAlign:'end'}} */}
            <Button
              type="button"
              className="btn btn-info"
              onClick={(e) => {
                setModalVisible(!modalVisible)
                setModalValue('')
                setMediaType(0)
              }}
            >
              Close
            </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DownloadContent;
