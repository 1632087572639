import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './profile.module.scss';
import {Button} from 'react-bootstrap';

function Buttons(props) {
    const {t} = useTranslation()
    return (
        <div>
            <Button className={`${style.Button} ${style.update} body-btn-outline`} onClick={props.submitHandler}>{t('Button.update')}</Button>
            <Button className={`${style.Button} ${style.cancel} body-btn-outline`} variant="light" style={{marginLeft: "5px"}} onClick={props.cancel} >{t('Button.cancel')}</Button>
        </div> 
    )
}

export default Buttons
