import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import travelPackageService from '../../../service/travelpackage';


function TravelPaymentView(props) {
    const params = useParams();
    let travel = new travelPackageService();

    useEffect(() => {
        console.log('inside trvel package')
        const localData = JSON.parse(localStorage.getItem('purchaseData'))
        const formData = {...params, ...localData}
        travel.paymentStatusCheck(formData)
            .then(res => {
                console.log('inside trvel package : ', res)
                if (res.status) {
                    alert('Thanks for join with our travel packages')
                    props.history.push({
                        pathname: `/mytravelpackages`,
                    })
                } else {
                    props.history.push({
                        pathname: `/payment_view_travel_fail/${formData.token}/${formData.PayerID}`,
                    })
                }
            })
    }, [])

    return (
        <div className="h-500" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "left": "0", "top": "-80px", "height": "calc(100vh)", "position": "fixed", "width": "calc(100%)" }}>
            <center><h3>Please wait while we process your payment</h3></center><br /><br /><br /><br />
            <Spinner />
        </div>
    );




}
export default withTranslation()(withRouter(TravelPaymentView));