// import { BASE_URL } from "../../configuration/apiconfig";
// import UserStore from "../../store/UserStore";
import API from "../../api/api";

export default class cc_shoppingService {
  API_KEY = localStorage.getItem("apiKey");

  //replacement of "package/repurchase_product"
  getRepurchasePackageList = async (id = 0) => {
    return await API.API.get(`package/cashback_community_product?category_id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          console.log('service :: ', res.data)
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  getRepurchasePackage = async (product_id) => {
    return await API.API.get(
      `package/repurchase_product_details?product_id=${product_id}`
    ).then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    });
  };


  updateCart = async (rowId, quantity) => {
    return await API.API.get(
      `package/updateItem?row_id=${rowId}&quantity=${quantity}`
    ).then((res) => {
      if (res.status === 204) {
        return {
          status: true,
        };
      } else {
        return res;
      }
    });
  };

  //Replacement of "package/add_to_cart", but no need the replacement "package/cc_add_to_cart"
  addToCart = async (paylod) => {
    console.log('paylod--> ', paylod);
    return await API.API.post("package/add_to_cart", paylod)
      .then((res) => {
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  // function for store the link clicked history in db
  linkClicked = async (paylod) => {
    return await API.API.post("package/store_ids?product_id=" + paylod?.product_id)
      .then((res) => {
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

//replacement of "package/getCartItems"
  getCart = async () => {
    return await API.API.get("package/getCashBackCommunityItems")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  removeItems = async (id) => {
    return await API.API.get(`package/removeItems?row_id=${id}`)
      .then((res) => {
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };



  getUserAddress = async () => {
    return await API.API.get("package/getUserAddress")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  removeAddress = async (id) => {
    return await API.API.post("package/RemoveAddress", id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  addAddress = async (payload) => {
    return await API.API.post("package/add_checkout_address", payload).then(
      (res) => {
       
        if (res.status===204) {
          return {
            status:true
          }
        } else {
          console.log("====",res)
          return res;
        }
      }
    );
  };



  setDefaultAddress = async (payload) => {
    return await API.API.post("package/change_default_address", payload)
      .then((res) => {
        if (res.status === 204) {
                  return {
          status: true,
        };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  submitShoppingCart = async (payload) => {
    console.log(payload)
    return await API.API.post("cart/repurchase_submit", payload)
      .then((res) => {
        if (res.status === 204) {
          return res.data
        } else {
          return res;
        }
      })
      .catch((eror) => console.log(eror));
  };



}
