
import React, { Component } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter as Router,
  // Route,Switch
} from 'react-router-dom';
import './App.scss';
import './style/customstyle.css';
import RouteMap from './route';
import { createBrowserHistory } from 'history';

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppFavicon(){
  return document.getElementById("fav");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
function getAppTitle(){
  return document.getElementById('companyname')
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: '',
      title:''
    }
  }
  
  componentDidMount() {
    fetch(
      "http://demo8.infinitemlmdemo.com/Michael_Jordan/backoffice/api/common/app_info", {
        // "https://admin.crowdfundedcashbackcommunity.com/backoffice/api/common/app_info", {      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api-key': '4eb2f3f2-d2bf-4255-a09b-c83609aca69f'
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          logo: json.data.company_info.favicon,
          title:json.data.company_info.company_name 
        });
        this.handleGoogle();
      })
  }
  handleGoogle() {
    const favicon = getFaviconEl(); // Accessing favicon element
    const fav = getAppFavicon();
    favicon.href = "http://demo8.infinitemlmdemo.com/Michael_Jordan/uploads/images/logos/" + this.state.logo;
    fav.href = "http://demo8.infinitemlmdemo.com/Michael_Jordan/uploads/images/logos/" + this.state.logo;
    // favicon.href = "https://admin.crowdfundedcashbackcommunity.com/uploads/images/logos/" + this.state.logo;
    // fav.href = "https://admin.crowdfundedcashbackcommunity.com/uploads/images/logos/" + this.state.logo;
  };
  handleApptitle(){
    getAppTitle()
    document.title=this.state.title;

  }
  render() {
    return (
      <Router>
        <RouteMap />
      </Router>
    )
  }
}
export default App;
