import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js"

export const PayPalPaymentButton = () => {

    const initialOptions = {
        "client-id": "test",
        currency: "USD",
        intent: "capture",
        "data-client-token": "abc123xyz==",
    }
    
    return (
        <PayPalScriptProvider options={{ "client-id": "AZ-lKlbZ5Jftydr-x_rIW5_su_0FSMHn3uEj8UmFdIPuCMN1UG5PGwnx0h53wlfWpDE8q-stqm9sQcuh" }}>
            <PayPalButtons style={{ layout: "horizontal" }}/>
        </PayPalScriptProvider>
    )
}

export default PayPalPaymentButton