import React, { useEffect, useState } from "react";
import style from "./Common.module.scss";
import {
  FreeJoin,
  BankTransfer,
  Ewallet,
  Epin,
  PurchaseWallet,
  PaypalPayment,
  NewPaypalPayment,
  PaypalCheckoutButton
} from "../Payments";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { useSelector } from "react-redux";
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js'

function PaymentMethods(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [checkout, setCheckout] = useState(false);
  const [state, setState] = useState({
    currentTab: 0,
  });
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentTab: props.selectedTab,
    }));
  }, [props.selectedTab]);
  //click the current tab
  const handleClick = (currentTab) => {
    props.paymentMethodChange(currentTab);

    setState((prev) => ({
      ...prev,
      currentTab: currentTab,
    }));
  };
  //create components
  const PaymentComponents = (type) => {
    switch (type) {
      case "free_purchase":
      case "freejoin":
        return <FreeJoin prevButtonClick={props.prevButtonClick} />;
      case "banktransfer":
        return (
          <BankTransfer
            prevButtonClick={props.prevButtonClick}
            userName={props.userName}
            type={props.payment_type}
          />
        );
      case "ewallet":
        return (
          <Ewallet
            payment_type={props.payment_type}
            product={props.product}
            EwalletData={props.ewallet}
            prevButtonClick={props.prevButtonClick}
          />
        );
      case "epin":
        return (
          <Epin
            payment_type={props.payment_type}
            prevButtonClick={props.prevButtonClick}
            product={props.product}
            epin={props.epin}
          />
        );
      case "authorize":
        return (
          <pre className={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Authorize payment available in live mode only
          </pre>
        );
      case "blockchain":
        return (
          <pre cclassName={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Blockchain payment available in live mode only
          </pre>
        );
      case "bitgo":
        return (
          <pre className={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Bitgo payment available in live mode only
          </pre>
        );
      case "payeer":
        return (
          <pre className={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Payeer payment available in live mode only
          </pre>
        );
      case "sofort":
        return (
          <pre className={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Sofort payment available in live mode only
          </pre>
        );
      case "squareup":
        return (
          <pre className={`${currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
            }`}>
            {" "}
            Squareup payment available in live mode only
          </pre>
        );
      case "purchase_wallet":
        return (
          <PurchaseWallet
            prevButtonClick={props.prevButtonClick}
            amount={props.amount}
            purchaseWallet={props.ewallet}
          />
        );


      // case "paypal":
      //   return checkout === true ? (
      //     <div className="payment-div">
      //        <PaypalPayment
      //         amount={props.amount}
      //         getPaypalData={props.getPaypalData}
      //       />
      //     </div>
      //   ) : (
      //     <div className="p-2">
      //       <pre className={`${
      //           currentTheme == "theme-dark"
      //             ? `${style.notreqnow2} alert alert-danger`
      //             : `alert alert-info ${style.notreqnow2}`
      //         }`}>{t("Common.clickFinish")}</pre>
      //       <div className={style.Buttons}>
      //         {props.prevButtonClick && (
      //           <CustomButton onClick={props.prevButtonClick} variant="success">
      //             {t("profile.prev")}
      //           </CustomButton>
      //         )}
      //         <CustomButton
      //           onClick={() => {
      //             setCheckout(true);
      //           }}
      //           variant="primary"
      //         >
      //           {t("Button.finish")}
      //         </CustomButton>
      //       </div>
      //     </div>
      //   );


      case "paypal":
        return (
         
            <NewPaypalPayment
              amount={props.amount}
              payment_type={props.payment_type}
              prevButtonClick={props.prevButtonClick}
              product={props.product}
              userName={props.userName}
            />  
         
          
        );


      // case "paypal":
      //   return (
      //     <PaypalCheckoutButton
      //     amount={props.amount}
      //     payment_type={props.payment_type}
      //     prevButtonClick={props.prevButtonClick}
      //     product={props.product}
      //     userName={props.userName}
      //     {...props}
      //   />  
      //   );


      // case "paypal":
      //   return checkout === true ? (
      //     <div className="payment-div">
      //       <PaypalCheckoutButton
      //         amount={props.amount}
      //         payment_type={props.payment_type}
      //         prevButtonClick={props.prevButtonClick}
      //         product={props.product}
      //         userName={props.userName}
      //         {...props}
      //       />
      //     </div>
      //   ) : (
      //     <div className="p-2">
      //       <pre className={`${currentTheme == "theme-dark"
      //           ? `${style.notreqnow2} alert alert-danger`
      //           : `alert alert-info ${style.notreqnow2}`
      //         }`}>{t("Common.clickFinish")}</pre>
      //       <div className={style.Buttons}>
      //         {props.prevButtonClick && (
      //           <CustomButton onClick={props.prevButtonClick} variant="success">
      //             {t("profile.prev")}
      //           </CustomButton>
      //         )}
      //         <CustomButton
      //           onClick={() => {
      //             setCheckout(true);
      //           }}
      //           variant="primary"
      //         >
      //           {t("Button.finish")}
      //         </CustomButton>
      //       </div>
      //     </div>
      //   );






      default:
        return null;
    }
  };
  return (
    console.log("These are the paypal props that are sent, ", props.getPaypalData),
    console.log("These are all the props sent ", props),
    <div className={style.PaymentMethods}>
      <div className={style.tab}>
        {props.methods.map((button, i) => (
          <button
            key={button.code}
            className={`${style.tablinks} ${state.currentTab === i && style.active
              }`}
            type="button"
            onClick={() => handleClick(i)}
          >
            <h4>
              <i className={button.icon}></i>
            </h4>
            {t(`Common.${button.code}`)}
          </button>
        ))}
      </div>
      <div className={style.tabcontent}>
        {state.currentTab !== -1 && (
          <React.Fragment>
            {props.methods.length > 0 &&
              PaymentComponents(props.methods[state.currentTab]?.code)}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default PaymentMethods;
