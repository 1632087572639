import React from "react";
import style from "./mail.module.scss";
import { Badge, Button, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function MailSidebar(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const changeTab = (menu, e) => {
    if (menu === "compose") {
      var element = document.getElementById(props.selecetedKey);
      element && element.classList.remove("active");
    } else {
      element = document.getElementById(props.selecetedKey);
      element && element.classList.add("active");
    }

    history.push({
      pathname: `/mailbox/${menu}`,
    });
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_leftPanel}`
          : `${style.leftPanel} bg-trasparent`
      } body-card border-0`}
    >
      <div className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_wrapper}`
            : `${style.wrapper} bg-trasparent`
        }`}>
        <Button
          type="submit"
          className={'btn-info-2'}
          onClick={(e) => changeTab("compose", e)}
        >
          {t("Button.compose")}
        </Button>
      </div>
      <Navbar.Toggle aria-controls="mail-box" />
      <Navbar.Collapse id="mail-box">
        <Nav
          className={`d-block ${style.navigation} mailbox-nav`}
          variant="pills"
          defaultActiveKey={props.selecetedKey}
          onSelect={props.changeTab}
        >
          <Nav.Link
            eventKey="inbox"
            onClick={(e) => changeTab("inbox", e)}
            id="inbox"
          >
            <span>
              <i className="fa fa-inbox"></i>
              {t("Button.inbox")}
              {props.unreadCount > 0 && (
                <Badge className={style.inboxBadge}>{props.unreadCount}</Badge>
              )}
            </span>
          </Nav.Link>
          <Nav.Link
            eventKey="send"
            onClick={(e) => changeTab("send", e)}
            id="send"
          >
            <span>
              <i className="fa fa-envelope-o"></i>
              {t("Button.send")}
            </span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MailSidebar;
