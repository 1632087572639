import React from 'react';
import {ListGroup,Image, Badge} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import { IMG_URL } from '../../../configuration/apiconfig';
import style from './mail.module.scss';
function MailList(props) {
   
    const {t} = useTranslation();
    return (
        <ListGroup.Item className={`${style.listItems} my-2`}>
            <span className={style.profileimage} onClick={()=>{props.view(props.index)}}>
                <Image src={IMG_URL+"profile_picture/mail_pro.png"} rounded/>
            </span>
            <div className={style.mailDetails} onClick={()=>{props.view(props.index)}}>
                <div>
                    <span>{props.fullname}({props.from_user_name??props.user_name})</span>
                    {
                        props.read_msg !=='yes'&&props.tab ==='inbox' &&
                            <Badge variant="primary" className={style.badgePrimary}>{t('Common.new')}</Badge>
                    }
                </div>
                <div>
                    <span>
                    {props.mailtoussub??props.mailadsubject}
                    </span>
                </div>
            </div>
            <div className={style.dateActions}>
                <div className={style.floatRight}>
                    <span onClick={()=>{props.view(props.index)}}> {props.mailtousdate??props.mailadiddate}</span>
                    <span className={style.deletemail} onClick={()=>props.delete(props.mail_enc_id,props.mail_enc_type)}>
                        <i className="fa fa-trash-o"></i>
                    </span>
                </div>
            </div>
        </ListGroup.Item>
    )
}

export default MailList
