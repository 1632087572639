import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation} from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import { withRouter } from 'react-router-dom';
import Spinner from '../../shared/Spinner';
import { CheckoutContent } from '../../components/shopping';
import shoppingService from '../../../service/shopping';
import cc_shoppingService from '../../../service/cc_shopping/cc_shoppingservice';
import Payments from '../../../service/common/PaymentApi';
import companyName from '../../../store/mobxStore/companyName';




function CheckOut(props) {
    let service = new shoppingService();
    let cc_service = new cc_shoppingService();
   // const {u} = useTranslation();
    let paymentService = new Payments();
    const [state, setState] = useState({
        loader: true,
        cart: [],
        address: [],
        paymentsMethods: [],
        alert: {
            show: false,
            varient: '',
            message: '',
            field: {
                count: 100
            }
        },
        modal: {
            show: false
        },
        selectedPeyment: '',
        paymentAmount: 0,
        payment: {
            username: '',
            password: '',
            pinArray: []
        }

    })

    useEffect(() => {
        loadCart();
        loadUseraddress();
        loadPayment();

    }, []);



    //load the cart items 
    const loadCart = () => {
        service.getCart().then(res => {
            if (res.status) {
                let cartItems = Object.values(res.data);
                let totalPrice = cartItems.reduce(function (accumulator, item) {
                    return accumulator + (item.price * item.qty);
                }, 0);
                setState(prevState => ({
                    ...prevState,
                    loader: false,
                    cart: cartItems,
                    paymentAmount: totalPrice
                }))
            } else {
                if (res.error.code === 1002) {
                    props.history.push('./logout');
                } else if (res.error.code === 1057) {
                    props.history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    })
                }
            }
        })
    }
    //load the user details address 
    const loadUseraddress = () => {
        service.getUserAddress().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    address: res.data.data
                }))
            } else {
                props.history.push('/logout');
            }
        })
    }
    //load payment data
    const loadPayment = () => {
        paymentService.getPaymentData('repurchase').then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    paymentsMethods: res.data,
                    selectedPeyment:res.data[0]
                }))
            
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                }
            }
        })
    }

    //update or remove items
    const updateCart = (action = 'update', id, quantity) => {
        if (action === 'update') {
            if (quantity > 100) {//validation greater then 100
                setState(prevState => ({
                    ...prevState,
                    alert: {
                        show: true,
                        message: 'validation.valueLessthanOrEquelto',
                        varient: 'danger',
                        field: {
                            count: 100
                        }
                    }
                }))
            } else if (quantity < 1) {
                setState(prevState => ({
                    ...prevState,
                    alert: {
                        show: true,
                        message: 'validation.valueGreaterThan0',
                        varient: 'danger'
                    }

                }))
            } else {
                service.updateCart(id, quantity).then(res => {
                    if (res.status) {
                        setState(prevState => ({
                            ...prevState,
                            alert: {
                                show: true,
                                message: 'validation.quantityUpdated',
                                varient: 'success'
                            }

                        }))
                        loadCart()
                    } else {
                        if (res.error.code === 1002) {
                            props.history.push('/logout')
                        }
                    }
                })
            }
        } else if (action === 'remove') {
            service.removeItems(id).then(res => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        alert: {
                            show: true,
                            message: t('validation.itemRemovedSuccessfully'),
                            varient: 'success'
                        }
                    }))
                    loadCart()
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout')
                    }
                }
            })
        }
    }

    //close alert
    const closeAlert = () => {
        setState(prevState => ({
            ...prevState,
            alert: {
                show: false,
                varient: '',
                message: ''
            }
        }))
    }

    //remove the address 
    const removeAddress = (id) => {
        const formData = {
            address_id: id
        }
        service.removeAddress(formData).then(res => {
            if (res.status) {
                loadUseraddress();
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                } else if (res.error.code === 1023) {
                    setState(prevState => ({
                        ...prevState,
                        alert: {
                            show: true,
                            varient: 'danger',
                            message: 'validation.errorInDelete'
                        }
                    }))
                }
            }
        })
    }

    //close modal 
    const toggleModal = () => {
        setState(prevState => ({
            ...prevState,
            modal: {
                show: !state.modal.show
            }
        }))
    }
    //submit the 
    const submitAddress = (data) => {
        service.addAddress(data).then(res => {
            if (res.status) {
                loadUseraddress();
                toggleModal();
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                }
            }
        })
    }
    //set the default address
const  setDefultAddress = (id) => {
        const formData = {
            addres_id: id
        }
        service.setDefaultAddress(formData).then(res => {
            if (res.status) {
                loadUseraddress()
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout')
                }
            }
        })
    }
const   setDefault = (id) => {
        const formData = {
            address_id: id
        }
        service.setDefaultAddress(formData).then(res => {
            if (res.status) {
                loadUseraddress();
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout');
                }
            }
        })
    }
    // Payment method change event
    const changeTabHandler = (tab) => {
        setState(prevstate =>({
            ...prevstate,
            selectedPeyment: state.paymentsMethods[tab]
        }))
    }
    //get the payment Data
    const getPurchaseData = (username, password) => {
        setState({
            payment: {
                ...state.payment,
                username: username,
                password: password
            }
        })
    }

    //submit the shopping cart
    const submitForm = (event) => {
        event.preventDefault();
        let FormData = {
            payment_method: state.selectedPeyment.code
        }
        if (state.selectedPeyment.code === 'purchase_wallet' || state.selectedPeyment.code === 'ewallet') {
            FormData.user_name = state.payment.username;
            FormData.password = state.payment.password
        } else if (state.selectedPeyment.code === 'epin') {
            FormData.epin = state.payment.pinArray.map((pin) => pin.pin);
        }
        // service.submitShoppingCart(FormData).then(res => {
        //     if (res.status) {
        //         console.log("response tha",res)

                // props.history.push({
                //     // pathname: `/repurchaseInvoice/${res.data.enc_order_id}`,
                //     pathname:`/repurchaseInvoice/${res.data.data.enc_order_id}`,
                //     state: {
                //         invoice_no: res.data.data.invoice_no,
                //         pending: res.data.data.pending
                //     }
                // })

        //     } else {
        //         if (res.error.code === 1002) {
        //             props.history.push('/logout');
        //         }
        //     }
        // })
    }
    const getEpinData = (pinArray) => {
        if (pinArray.length > 0) {
            setState(prevState => ({
                ...prevState,
                payment: {
                    pinArray
                }
            }))
            // setState({
            //     payment: {
            //         pinArray
            //     }
            // })
        }
        console.log("state",state)
    }
    const { t } = props;

    const vsuccess = t('validation.itemRemovedSuccessfully');
    
    if (state.loader) {
        return <Spinner />
    }
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.checkout')}</title>
            </Helmet>
            <PageTitle title="checkout" buttonOn={false} />
            <CheckoutContent
                {...state}
                close={closeAlert}
                tabChange={changeTabHandler}
                updateCart={updateCart}
                removeAddress={removeAddress}
                modalToogle={toggleModal}
                addAddress={submitAddress}
                setDefault={setDefault}
                purchaseWallet={getPurchaseData}
                submitForm={submitForm}
                epin={getEpinData}
            />
        </div>
    )

}
export default withTranslation()(withRouter(CheckOut))