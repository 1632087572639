import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//components
import { ProfileImage, ChangePassword, SuccessModal } from "./";
import style from "./profile.module.scss";
import {
  UserIcon,
  UserGroup,
  ArrowLeft,
  ArrowRight,
} from "../../../assets/icons";
import AlertMessage from "../common/AlertMessage";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

function Content(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };
  //state
  const [state, setState] = useState({
    modal: {
      show: false,
      modalType: "",
      icon: "",
    },
    notify: notifyInintialState,
    isSuccess: false,
  });

  useEffect(() => {}, [state.modal.type, props]);

  //state variable
  const { modal } = state;

  //changepassowrd button click
  const changeButtonClick = (modalType) => {
    let icon = "fa fa-key";
    if (modalType === "changePassword") {
      icon = "fa fa-lock";
    }
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: true,
        modalType: modalType,
        icon: icon,
      },
    }));
  };

  //onclose model
  const closeModal = () => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: false,
        modalType: "",
      },
      isSuccess: false,
    }));
  };

  //on dismiss notify
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };

  //show totify message
  const messageShow = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: type === "success" ? false : true,
      },
      isSuccess: type === "success" ? true : false,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: header,
        message: message,
      },
    }));
  };
  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: type === "success" ? false : type === "error" ? false : true,
      },
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };

  return (
    <div className={style.ProfileDetails}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_panel}`
            : `${style.panel}`
        } body-card`}
      >
        <div className={`${style.profileUser} d-none`}>
          <div className={style.ProfileSection}>
            <div className={style.MainProfileDetails}>
              <ProfileImage
                image={props.profile.user_photo}
                notify={showNotify}
                getData={props.getData}
                onDeletePic={props.onDeletePic}
              />
              <h3
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_ProfileFullName}`
                    : `${style.ProfileFullName}`
                }`}
              >
                {props.profile.full_name}
              </h3>
              <h5
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_ProfileUserName}`
                    : `${style.ProfileUserName}`
                }`}
              >
                {props.profile.user_name}
              </h5>
              <p
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_UserEmail}`
                    : `${style.UserEmail}`
                }`}
              >
                {props.profile.email}
              </p>
              <span
                onClick={() => {
                  changeButtonClick("changePassword");
                }}
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_PasswordButton}`
                    : `${style.PasswordButton}`
                }`}
              >
                {t("Common.changePassword")}
              </span>
              <span
                onClick={() => {
                  changeButtonClick("changeTransactionPassword");
                }}
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_PasswordButton}`
                    : `${style.PasswordButton}`
                }`}
              >
                {t("Common.changeTransactionPassword")}
              </span>
              {props.profile.hasOwnProperty("kyc_status") && (
                <div
                  className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_kyc}`
                      : `${style.kyc}`
                  }`}
                >
                  <div>
                    {t("Common.kyc")} :
                    {props.profile.kyc_status ? (
                      <span className={style.Verified}>
                        {" "}
                        {t("Common.verified")}
                      </span>
                    ) : (
                      <span className={style.notVerified}>
                        {" "}
                        {t("Common.notverified")}
                      </span>
                    )}
                  </div>
                  {/* <button> */}
                  <Link to="/kyc_upload" className={style.KycLink}>
                    {t("Common.moreinfo")}
                  </Link>
                  {/* </button> */}
                </div>
              )}
            </div>
          </div>
          <div className={style.ExtraDetails}>
            {props.profile.rank && (
              <h6
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_rankText}`
                    : `${style.rankText}`
                }`}
              >
                {t("Common.rank")} :{" "}
                <span
                  className={`${style.rankName}`}
                  style={{ color: props.profile.rank.rank_color }}
                >
                  {props.profile.rank.curent_rank}
                </span>
              </h6>
            )}
            
            {/* upgrade and renew nono start */}

            <div className={style.packageRenewal}>
              {props.profile.membership_package && (
                <>

                
                  {/* <div className={style.package}>
                    <p
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_Packagetext}`
                          : `${style.Packagetext}`
                      }`}
                    >
                      {t("Dashboard.membershipPackage").toUpperCase()}
                    </p>
                    <p
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_packageName}`
                          : `${style.packageName}`
                      }`}
                    >
                      {props.profile.membership_package.name}
                    </p>
                    {(props.profile.membership_package.upgrade_link ||
                      props.profile.membership_package.upgrade_link === "") && (
                      <div className={style.packageButton}>
                        {props.profile.membership_package.upgrade_link
                          .length === 0 ? (
                          <Link
                            to="/upgrade_package"
                            className={style.renewButton}
                          >
                            {t("Dashboard.upgradeMembership")}
                          </Link>
                        ) : (
                          <a
                            href={props.profile.membership_package.upgrade_link}
                            className={style.renewButton}
                          >
                            {t("Dashboard.upgradeMembership")}
                          </a>
                        )}
                      </div>
                    )}
                  </div> */}


                  {
                    /*
                    props.profile.membership_package.product_validity
                    .date != 'NA' && (
                      <div className={style.renew}>
                        {props.profile.membership_package.product_validity && (
                          <>
                            <p
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_ExpiryText}`
                                  : `${style.ExpiryText}`
                              }`}
                            >
                              {t("Dashboard.membershipExpiry")}
                            </p>
                            <p
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_ExpiryDate}`
                                  : `${style.ExpiryDate}`
                              }`}
                            >
                              {
                                props.profile.membership_package.product_validity
                                  .date
                              }
                            </p>
                            {(props.profile.membership_package.product_validity
                              .renewal_link ||
                              props.profile.membership_package.product_validity
                                .renewal_link === "") && (
                              <div className={style.renewButtons}>
                                {props.profile.membership_package.product_validity
                                  .renewal_link.length === 0 ? (
                                  <Link
                                    // to="/upgrade_validity"
                                    to="/cc_shopping"
                                    className={style.renewButton}
                                  >
                                    {" "}
                                    {t("Dashboard.renewMembership")}
                                  </Link>
                                ) : (
                                  <a
                                    href={
                                      props.profile.membership_package
                                        .product_validity.renewal_link
                                    }
                                    className={style.renewButton}
                                  >
                                    {t("Dashboard.renewMembership")}
                                  </a>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )
                    */
                  }
                </>
              )}
            </div>
            
            {/* upgrade and renew nono end */}

            {/* <div className={style.sponsorPlacement} style={{"marginTop" : "-10%"}}> */}
            <div className={style.sponsorPlacement}>
              {props.extraData &&
                props.extraData.placement.map((items, index) => {
                  return (
                    <div key={index}>
                      <p
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_textheader}`
                            : `${style.textheader}`
                        }`}
                      >
                        {t("Common." + items.text)}
                      </p>
                      <p
                        style={{ textTransform: "capitalize" }}
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_textvalue}`
                            : `${style.textvalue}`
                        }`}
                      >
                        <span>{items.head}</span>
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className={style.UserDetails}>
              {props.extraData.pv &&
                props.extraData.pv.map((items, index) => {
                  return (
                    <div
                      className={`d-flex ${style.PersonalDetails} ${
                        index !== props.extraData.pv.length - 1 &&
                        style.verticalLine
                      }`}
                      key={index}
                    >
                      {items.text === "personalPv" && (
                        <div className={`${style.Icon} ${style.bgPurple}`}>
                          {" "}
                          <UserIcon />{" "}
                        </div>
                      )}
                      {items.text === "groupPV" && (
                        <div className={`${style.Icon} ${style.bgyellow}`}>
                          {" "}
                          <UserGroup />{" "}
                        </div>
                      )}
                      {items.text === "leftCarry" && (
                        <div className={`${style.Icon} ${style.bgskyblue}`}>
                          {" "}
                          <ArrowLeft />{" "}
                        </div>
                      )}
                      {items.text === "rightCarry" && (
                        <div className={`${style.Icon} ${style.bglight}`}>
                          {" "}
                          <ArrowRight />{" "}
                        </div>
                      )}
                      <div>
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_userTitle}`
                              : `${style.userTitle}`
                          }`}
                        >
                          {t("Common." + items.text)}
                        </div>
                        <div className={`${style.userHead} `}>{items.head}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <Row className="profile-top-row p-2 py-3">
          <Col lg={6} md={6} sm={6} className="left-section">
            <div className="d-flex w-100">
              <Row className="w-100">
                  <Col lg={4} md={5} sm={5} xs={12}>
                    <div className="d-flex justify-content-center w-100">
                      <div className="img-div">
                        <img src={props.profile.user_photo} className="img-thumbnail bg-transparent border-0" />
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} md={7} sm={7} xs={12}>
                    <div className="d-flex h-100 gap-2 flex-column justify-content-between primary-color">
                      <div>{props.profile.user_name}</div>
                      <div>{props.profile.email}</div>
                      <div className="d-flex gap-2">
                      <span
                        onClick={() => {
                          changeButtonClick("changePassword");
                        }}
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_PasswordButton}`
                            : `${style.PasswordButton}`
                        } body-btn-outline`}
                      >
                        {t("Common.changePassword")}
                      </span>
                      <span
                        onClick={() => {
                          changeButtonClick("changeTransactionPassword");
                        }}
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_PasswordButton}`
                            : `${style.PasswordButton}`
                        } body-btn-outline`}
                      >
                        {t("Common.changeTransactionPassword")}
                      </span>
                      </div>
                    </div>
                  </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={6} sm={6}>
          </Col>
        </Row>
      </div>
      <ChangePassword
        {...modal}
        onClose={closeModal}
        setNotify={messageShow}
        showNotify={showNotify}
        policy={props.profile.password_policy}
      />
      {state.notify.show && (
        <AlertMessage
          type={state.notify.type}
          message={state.notify.message}
          show={state.notify.show}
          dismiss={notifyDissmissed}
        />
      )}
      {/* <SuccessModal 
                show={state.isSuccess}
                closeModal={closeModal}
            /> */}
    </div>
  );
}

export default Content;
