import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import CC_CartContent from '../../components/cc_shopping/CartContent';
import Spinner from '../../shared/Spinner';
import cc_shoppingService from '../../../service/cc_shopping/cc_shoppingservice';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';



function CC_ShoppingCart(props) {
    let service = new cc_shoppingService();
    let history = useHistory();



    const [state, setState] = useState({
        data: [],
        loader: true,
        alert: {
            show: false,
            varient: '',
            message: ''
        },
        cart: []

    })

    const {id} = props.match.params;

    useEffect(() => {
        const location = props.location;
        if (location) {

            const { t } = props;
            let varient = 'danger';
            let message = '';
            if (location.success) {
                varient = 'success';
                message = location.update ? 'quantityUpdated' : 'addToCartSuccess'
            }


        }
        service.getRepurchasePackageList(id)
            .then(res => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        data: res.data.products,
                        loader: false
                    }));


                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
            })
        getCart();


    }, [id]);

    const getCart = () => {
        service.getCart().then(res => {
            if (res.status) {
                let cartItems = Object.values(res.data);
                setState(prevState => ({
                    ...prevState,
                    cart: cartItems
                }));

            } else {
                history.push('/logout');
            }
        })
    }

    const removeCart = (action = 'remove', id, quantity) => {
        if (action === 'remove') {
            service.removeItems(id).then(res => {
                if (res.status) {
                    getCart()
                    closeAlert()
                    // console.log("Success", res)
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout')
                    }
                }
            })
        }
    }

    const repurchaseReport = () => {
        history.push('/repurchaseReport')
    }

    const ButtonGroup = [
        {
            name: 'repurchaseReport',
            click:  repurchaseReport
        }
    ];

    //close alert
    const closeAlert = () => {
        setState(prevState => ({
            ...prevState,
            alert: {
                show: false,
                varient: '',
                message: ''
            }
        }))
    }

    //add to cart
    const addToCart = (id, name) => {

        let shoptype = ''

        window.location.pathname.includes('/cc_') ? shoptype = 'cashback_community' : shoptype = 'repurchase'


        const formData = {
            product_id: id,
            product_qty: 1,
            shop_type : shoptype
        }
    
        service.addToCart(formData).then(res => {
            if (res.status) {
                getCart()
                setState(prevState => ({
                    ...prevState,
                    alert: {
                        show: true,
                        varient: 'success',
                        message: t(`validation.addToCartSuccess`, { name: name })
                    }
                }));
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            } else {
                if (res.error.code === 1002) {
                    history.push('/logout')
                } else if (res.error.code === 1050) {
                    setState(prevState => ({
                        ...prevState,
                        alert: {
                            show: true,
                            varient: 'danger',
                            message: t(`validation.invalidProduct`)
                        }
                    }));
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
            }
        })
    }

    // function for store link clicked
    const storeLinkClick = (id, amazon_link) => {
        service.linkClicked({product_id:id}).then(res => {
            if (res.status === 200) {
                window.open(amazon_link, '_blank')
            }
        })
    }


    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.shopping')}</title>
            </Helmet>
            <PageTitle title="cashcommu"
                buttonOn={false}
                AddonIcon={true}
                icon="fa fa-bar-chart"
                group={ButtonGroup}
                />
            <CC_CartContent
                {...state}
                closeAlert={closeAlert}
                addToCart={addToCart}
                storeLinkClick={storeLinkClick}
                removeCart = {removeCart}
            />
        </div>


    );







}
export default withTranslation()(withRouter(CC_ShoppingCart));