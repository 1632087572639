import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import {withRouter} from 'react-router-dom';
import travelPackageService from '../../../service/travelpackage';
import MyTravelPackageReport from '../../components/shopping/MyTravelPackageReport';


function MyOrderReport(props){
    let travel = new travelPackageService();
    const [state, setState] = useState({
        loader  :false,
        filter : {
            start : '',
            end: '',
            label : ''
        },
        report : {
            totalRows   : 0,
            data        : [],
            pending     : true,
            perPage     : 10,
            inPage      : 1,
        }
    })

    useEffect(() => {
        loadData();
    }, []);

        //load the data
    const loadData = () => {
        travel.getMyTravelPackages().then(res=>{
            if(res.status){
                // console.log('fff :: ', res.data, res.data.data)
                setState(prevState => ({
                    ...prevState,
                    report: {
                        ...prevState.report,
                        totalRows : res.data.total_row,
                        data : res.data.data,
                        pending : false
                    }
                }))
            }else{
                if(res.error.code === 1002){
                    props.history.push('/logout');
                }else if(res.error.code ===1057){
                    props.history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message : 'permissionDenied'
                        }
                    })
                }
            }
        })
    }


    const { t } = props;
    if(state.loader) {
        return <Spinner />
    }
    return (

        <div className="h-100">
            <Helmet>
                <title>My travel package Report</title>
            </Helmet>
            <PageTitle isUpdate={true} title="my travel package Report" />
            <MyTravelPackageReport 
                {...state}
                changeDate={''}
                resetButton={''}
                submitFilter={''}
                paginationchange={''}
            />
        </div>
    )


}
export default withTranslation()(withRouter(MyOrderReport));