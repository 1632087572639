import { reducer } from 'easy-peasy'; 
// import {combineReducers} from 'redux';
import LoggedReducer     from './isLogged';
import changeLangReducer from './changLang';
import changeCurrReducer from './currency';
import changeMenuReducer from './MenuReducer';
import NotificationReducer from './notificationReducer';
import MailReducer from './mail';
import ProjectReducer from './projectConfig';
import replicaLangReducer from './replicaLang';
import changeThemeReducer from "./changeTheme";
// import samplemodel from './sample';



const allReducer = {
    isLogged    : reducer(LoggedReducer),
    Lang        : reducer(changeLangReducer),
    curr        :reducer(changeCurrReducer),
    Menu        : reducer(changeMenuReducer),
    Notification : reducer(NotificationReducer),
    Mail        : reducer(MailReducer),
    ProjectConfig   : reducer(ProjectReducer),
    repLang:reducer(replicaLangReducer),
    Theme:reducer(changeThemeReducer)
    // sample :reducer(samplemodel),
  
  };
// const allReducer = combineReducers({
//     isLogged    : LoggedReducer,
//     Lang        : changeLangReducer,
//     curr        : changeCurrReducer,
//     Menu        : changeMenuReducer,
//     Notification : NotificationReducer,
//     Mail        : MailReducer,
//     ProjectConfig   : ProjectReducer,
//     repLang:replicaLangReducer
// });

export default allReducer;