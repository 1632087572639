import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation}from 'react-i18next';
import style from './registration.module.scss';
function Buttons(props) {
    const {t} = useTranslation();
    return (
        <div className={style.Buttons}>
                {
                    props.prev &&
                    <Button color="primary" onClick={props.prevButtonClick} className={`btn-pill ${style.PrevButton} btn-info`} >
                        <i className="fa fa-chevron-left" />
                        {t('profile.prev')} &nbsp;
                    </Button>
                }
                <Button  className={`btn-pill ${style.NextButton} btn-primary`} type="submit">
                {!props.next? t('profile.next') :t('Button.finish') } &nbsp;
                <i className="fa fa-chevron-right" />
                </Button>
        </div>
    )
}

export default Buttons
