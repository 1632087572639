import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../../../shared/Spinner';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state) => {
    console.log("state====>>>", state);
    return { 
        mlmPlan: state.ProjectConfig.mlmPlan 
    };
};

const ManageProductRefComponent = ({ mlmPlan }) => {
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        let _isMounted = true;

        const isLogged = JSON.parse(localStorage.getItem('login'));
        localStorage.setItem('productRefLink', JSON.stringify(params));

        if (_isMounted) {
            if (isLogged) {
                history.push('/dashboard');
            } else {
                // localStorage.setItem('productRefLink', JSON.stringify(params));
                history.push('/referral_register/' + params?.sponser_id);
            }
        }

        return () => {
            _isMounted = false;
        };
    }, [params, history]);

    return (
        <>
            <Spinner />
        </>
    );
};

export default withTranslation()(connect(mapStateToProps)(withRouter(ManageProductRefComponent)));
