import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TravelContent = (props) => {
  const [clicked, setClicked] = useState(0)
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const currency = useSelector((state) => state.curr);
  let fetchData = props?.data

  console.log('f d : ', props)
  return (
    <div className="package-main-div p-5">
      <Row>
      {
        fetchData?.map((item, index) => {
          return (
            <Col sm={12} xs={12} lg={4}>
              <Card className="travel-card pt-3 pb-3 body-card">
                <div className="ml-4 mr-4 travel-card-div">
                  <div className="package-title">
                    {/* <span className="package-title-span text-white">{item?.package_name}</span> */}
                    <div class="ribbon-pop">{item?.package_name}</div>
                  </div>
                  <div className="package-pricing text-center">
                    <div>
                      <span className="package-installment"><span>$</span>{item?.package_installment}</span>
                      <div>
                        <span className="h6 text-white font-weight-bold mb-0">One Time</span>
                      </div>
                    </div>
                    <div className="package-month">
                        <span>then ${item?.package_price} per month</span>
                    </div>
                  </div>
                </div>
                <div className="ml-4 mr-4 package-description">
                  <div dangerouslySetInnerHTML={{__html:item?.package_description}} />
                </div>
                <div className="text-center">
                  <Button
                    className="package-join-button"
                    onClick={() => {
                      setClicked(index + 1)
                      props?.onclick(item?.id)
                      setTimeout(() => {
                        setClicked(0)
                      }, 3500)
                    }}
                    disabled={clicked === (index + 1)}
                  >
                    {Number(item?.is_purchase) > 0 ? 'Subscribed' : `${clicked === (index + 1) ? 'Please Wait' : 'Join Now'}`}
                  </Button>
                </div>
              </Card>
            </Col>
          )
        })
      }
      </Row>
    </div>
  );
};
export default TravelContent;
