import React from 'react';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './mail.module.scss';
function MailType(props) {
    const {t} = useTranslation();
    return (
        <Form.Group className={style.type}>
            <Form.Label className={style.radiolabel}>{t('Common.type')}</Form.Label>
            <div className={style.mail_type_section}>
                <div>
                    <label className={style.radioButton} >
                        <input
                        type="radio"
                        value="admin"
                        name="type"
                        onChange={props.changeHandler}
                        checked={props.state.type === "admin"}
                        className={style.mail_type_radio_input}
                        // checked
                        />
                        {t('Common.admin')}
                    </label>
                </div>
                <div >
                    <label className={style.radioButton}>
                        <input
                        type="radio"
                        value="myTeam"
                        name="type"
                        onChange={props.changeHandler}
                        checked={props.state.type === "myTeam"}
                        className={style.mail_type_radio_input}
                        />
                        {t('Common.myTeam')}
                    </label>
                </div>
                <div >
                    <label className={style.radioButton}>
                        <input
                        type="radio"
                        value="individual"
                        name="type"
                        onChange={props.changeHandler}
                        checked={props.state.type === "individual"}
                        className={style.mail_type_radio_input}
                        />
                        {t('Common.individual')}
                    </label>
                </div>
                <div>
                    <label className={style.radioButton}>
                        <input
                        type="radio"
                        value="externalMail"
                        name="type"
                        onChange={props.changeHandler}
                        checked={props.state.type === "externalMail"}
                        className={style.mail_type_radio_input}
                        />
                        {t('Common.externalMail')}
                    </label>
                </div>
            </div>
        </Form.Group>
        
    )
}

export default MailType
