import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomButton } from '../common';
import { useTranslation } from 'react-i18next';
import shoppingService from '../../../service/shopping';
import style from './payment.module.scss';
import { Button } from 'react-bootstrap';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js'; 
import { useHistory } from 'react-router';





function NewPaypalPayment(props) {
  const { t } = useTranslation();
  let history = useHistory();
  let shopservice = new shoppingService();

  const [state, setState] = useState({
    all_amount: props.amount,
    invoice_url: '',
    paymentmethodsend: {
      payment_method: "paypal"
    },
  })



  const Finishbutton = (url, e) => {
    console.log("The need to be redirected url is ",url)
    // e.preventdefault()
    // alert('your order is renewed');
    // history.push('myorderReport');
    window.location.href = url;
  }

  const getNowInvoiceurl = (e) => {

    console.log(e)
    // e.preventDefault();

    shopservice.submitShoppingCart(JSON.stringify(state.paymentmethodsend)).then(res => {
      console.log("Result for shopping status is ", res.status)
      console.log("The response inside shopping is ",res)
      if (res.status) {
        console.log("Success res inside shopping is ", res)
        setState(prevState => ({
          ...prevState,
          invoice_url: res.data.data.url,

        }));

      }
      else {
        // setState(prevState => ({
        //     ...prevState,
        //     error_msg: res.data.message,
        //     error_flag: true

        // }));

        //Invalid access token logout
        if (res.error.code === 1002) {
          props.history.push('/logout');
        }

      }

    })




  }



  const trytogetInvoiceurl = async (pm) => {

    try {
      const response = await shopservice.submitShoppingCart(pm)
      console.log("The response of trytogetInvoiceurl is ", response)

      setState(prevState => ({
        ...prevState,
        invoice_url: response.data.data.url,
      }));
    }
    catch (error) {
      console.log("The error of trytogetInvoiceurl is ",error)
    }
  }

  return (
    console.log("The amount that has been passed to here is ", props.amount),

    // console.log(window.location.pathname),
    <div className={style.MianContent}>
      <div>
        {
          state.invoice_url !== '' ?
            <pre className={`alert alert-info`}>Click finish</pre>
            :
            <pre className={`alert alert-info`}>Click proceed to continue</pre>
        }


 

        <div className={style.Buttons}>
          {
            props.prevButtonClick &&
            <Button color="primary" onClick={props.prevButtonClick} className={`btn-pill  ${style.PrevButton}`} style={{ marginRight: '20px' }} >
              <i className="fa fa-chevron-left" />

              {t('profile.prev')} &nbsp;
            </Button>
          }

          
          {
            state.invoice_url !==  '' ?
              <Button color="primary" onClick={(e) => { Finishbutton(state.invoice_url, e) }} className={`btn-pill ${style.NextButton}`} type="submit" style={{ marginRight: '20px' }}>
                Finish &nbsp;
              </Button> :

              <Button color="primary" onClick={() => { getNowInvoiceurl() }} className={`btn-pill ${style.NextButton}`} type="submit" style={{ marginRight: '20px' }}>
                Proceed &nbsp;
              </Button>           

              // <Button color="primary" onClick={() => { trytogetInvoiceurl(JSON.stringify(state.paymentmethodsend)) }} className={`btn-pill ${style.NextButton}`} type="submit" style={{ marginRight: '20px' }}>
              //   Proceed &nbsp;
              // </Button>


          }


        </div>


      </div>

    </div>
  )
}

export default NewPaypalPayment
