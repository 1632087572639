import React,{ useState } from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import "react-data-table-component-extensions/dist/index.css";
import {CurrencyDecimalFormat} from '../../helper';
import style from './invoice.module.scss';
import shoppingService from '../../../service/shopping';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';

function MySubscriptionTable(props) {
    let service = new shoppingService();
    let history = useHistory();
    
    const {t} = useTranslation();
    const Currency = useSelector(state=>state.curr)
    const columns = [
        {
            name: 'Action',
            selector: row => (
                <Button
                    onClick={() => {
                        addToCart(row)
                    }}
                >Renew</Button>
            ),
            //   width : "30%"
        },
        {
          name: 'Product Name',
          selector: row => row.product_name,
        //   width : "30%"
        },
        {
            name: 'Amount',
            selector: row => row.amount
        },
        {
            name: 'Quantity',
            selector: row => row.quantity
        },
        {
            name: 'Total Amount',
            selector: row => row.total
        },
        {
            name: 'Subscription Date',
            selector: row => row.subscription_start_date
        },
        {
          name: 'Rrenewal Date',
          selector: row => row.subscription_expiry_date
        },
        {
          name: 'emaining Days',
          selector: row => row.remaining_days
        }
      ];    

      const [state, setState] = useState({
        data: [],
        loader: true,
        alert: {
            show: false,
            varient: '',
            message: ''
        },
        cart: []

    })

    const handlePerRowsChange = (perPage, inPage) => {
        props.paginationchange(
            inPage,
            perPage
        )
    };
    const handlePageChange = inPage => {
        props.paginationchange(
            inPage,
            props.perPage
        )
    };

    const getCart = () => {
        service.getCart().then(res => {
            if (res.status) {
                let cartItems = Object.values(res.data);
                setState(prevState => ({
                    ...prevState,
                    cart: cartItems
                }));

            } else {
                history.push('/logout');
            }
        })
    }

    const addToCart = (row = {}) => {
        let id = row?.product_id;
        let name = row?.product_name;

        let shoptype = ''

        shoptype = 'repurchase'
        const formData = {
            product_id: id,
            product_qty: 1,
            shop_type: shoptype
        }

        service.addToCart(formData).then(res => {
            if (res.status) {
                history.push('/cc_shopping')
            } else {
                if (res.error.code === 1002) {
                    history.push('/logout')
                } else if (res.error.code === 1050) {
                    setState(prevState => ({
                        ...prevState,
                        alert: {
                            show: true,
                            varient: 'danger',
                            message: t(`validation.invalidProduct`)
                        }
                    }));
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
            }
        })
    }

    return (
        <div>
            <DataTable
                    columns={columns}
                    data={props.data}
                    highlightOnHover
                    progressPending={props.pending}
                    progressComponent={<div>Loading</div>}
                    persistTableHead
                    noHeader
                    pagination
                    paginationServer
                    responsive
                    paginationTotalRows={props.totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={t('Common.noDataRecordsToDisplay')}
                    // onSort={handleOnSort}
                    />
        </div>
    )
}

export default MySubscriptionTable
