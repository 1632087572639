import React, { useEffect } from "react";
import style from "./cart.module.scss";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddonNotification, AlertBs } from "../common";
import CartTable from "./CartTable";
import Nophoto from "../../../assets/images/nophoto/no_photo.jpg";
import Slider from "react-slick";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CartContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const currency = useSelector((state) => state.curr);

  let newCategory = props.data.map(q => q.category)
  let newCategoryList = newCategory.filter((q, idx) => newCategory.indexOf(q) === idx)

  let xdata = {};
  let data_our = [];

  props.data.map((item, index) => {
    newCategoryList.forEach(function (unicat) {
      if (unicat === item.category) {
        data_our.push({
          'code': unicat,
          'items': item
        })
        //xdata[unicat] = item
      }
      else {

      }
    })

  })

  const gohere = (x) => {
    if(x!== '')
    {
      window.open(x, '_blank')
    }
  }


  let cats = props.data.reduce((catsSoFar, { amount,amazon_link, category, id, image, prod_id, product_name }) => {
    if (!catsSoFar[category]) catsSoFar[category] = [];
    catsSoFar[category].push({
      'amount': amount,
      'amazon_link' : amazon_link,
      'category': category,
      'id': id,
      'image': image,
      'prod_id': prod_id,
      'product_name': product_name
    });
    return catsSoFar;
  }, {});

  // console.log("The cat is here", cats)
  // console.log("The data is here", data_our)
  // console.log("xdata", xdata)


  const viewPackage = (id) => {
    history.push(`/package_details/${id}`);
  };


  const caro_settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  const responsiveyo = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    console.log("The category is ", newCategoryList),
    <div className={style.container}>
      <AlertBs
        varient={props.alert.varient}
        show={props.alert.show}
        message={props.alert.message}
        close={props.closeAlert}
      />
      {/* <AddonNotification /> */}
      {props.cart && props.cart.length > 0 && <CartTable cart={props.cart} fromwhere={"internal"} removeCart={props.removeCart} />}


      {/* <Row>
        {props.data.map((item, index) => (
          console.log(`The item and index in shopping is ${item} and ${index} `),
          console.log(item),

          // newCategoryList[index] == item.category ?


          <Col key={index} md={4} lg={4}>
            <Card>
              <Card.Body className={`${style.panel}`}>
                <div className={`text-center`}>
                  {item.image ? (
                    <img
                      src={item.image}
                      className={style.cartItemImage}
                      alt="No Image"
                    />
                  ) : (
                    <img
                      src={Nophoto}
                      className={style.cartItemImage}
                      alt="No Image"
                    />
                  )}
                </div>
                <div className={style.itemTitle}>
                  <h4>{item.product_name}</h4>
                </div>
                <div className={style.textCenter}>
                  <span className={style.categoryTxt}>{item.category}</span>
                </div>
                <div className={style.textCenter}>
                  <small className={style.price}>
                    {currency.currentCurr}{" "}
                    {(item.amount * currency.value).toFixed(currency.precesion)}
                  </small>
                </div>
              </Card.Body>
              <Card.Footer className={style.noPadder}>
                <Row className={style.noGutter}>
                  <Col md={6} lg={6} style={{ padding: 0 }}>
                    <div className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_wrapper} text-center`
                          : `${style.wrapper} ${style.br} text-center`
                      }`}>
                      <Button
                        variant="info"
                        className={`${style.btnAddon} ${style.btnAddonInfo}`}
                        onClick={() => {
                          props.addToCart(item.id, item.product_name);
                        }}
                      >
                        <i className="fa fa-shopping-cart"></i>
                        {t("Button.addToCart")}
                      </Button>
                    </div>
                  </Col>

                  <Col md={6} lg={6} style={{ padding: 0 }}>
                    <div className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_wrapper} text-center`
                          : `${style.wrapper} ${style.br} text-center`
                      }`}>
                      <Button
                        onClick={() => viewPackage(item.id)}
                        variant="success"
                        className={`${style.btnAddon} ${style.btnAddonSuccess}`}
                      >
                        <i className="fa fa-eye"></i>
                        {t("Button.moreDetails")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>

          // :
          // <></>
          
        ))}
      </Row> */}


      {


        //  Object.entries(cats).map(function(key,index) {
        Object.keys(cats).map(function (key, index) {
          return (
            <>
              <div className={style.headingforallcards}>
                <b>{key.toUpperCase()}</b>
              </div>

              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows = {true}
                renderArrowsWhenDisabled = {false}
                responsive={responsiveyo}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                // customTransition="all .5 ease-in"
                customTransition="transform 1.1s"
                // customTransition="transform 300ms ease-in-out"
                transitionDuration={500}
                // containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={"desktop"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                
              >

                {/* <Row style={{ "marginBottom": "20px" }}> */}


                  {/* <Slider {...caro_settings}> */}
                  {cats[key].map((againitem, againindex) => (


                    // <Col key={againindex} md={4} lg={4} >
                    <Col key={againindex} >
                      <Card className="body-card">
                        <Card.Body className={`${style.panel}`}>
                          <div className={`text-center`}>
                            {againitem.image ? (
                              <img
                                src={againitem.image}
                                className={style.cartItemImage}
                                onClick={() => {
                                    // gohere(againitem.amazon_link);
                                    props.storeLinkClick(againitem.id, againitem.amazon_link);
                                  }}
                                alt="No Image"
                              />
                            ) : (
                              <img
                                src={Nophoto}
                                className={style.cartItemImage}
                                alt="No Image"
                              />
                            )}
                          </div>
                          <div className={style.itemTitle}>
                            <h4>{againitem.product_name}</h4>
                          </div>
                          <div className={style.textCenter}>
                            <span className={style.categoryTxt}>{againitem.category}</span>
                          </div>
                          <div className={style.textCenter}>
                            {/* {console.log("The againitem is ",againitem)} */}
                            <Button
                                  variant="info"
                                  style={{"marginTop" : "3%"}}
                                  // className={againitem.amazon_link === "" ? `${style.btnAddon} ${style.btnAddonDanger}` : `${style.btnAddon} ${style.btnAddonSuccess}`}
                                  className={againitem.amazon_link === "" ? `${style.btnAddon} ${style.btnAddonDanger} btn-info-2` : `${style.btnAddon} ${style.btnAddonSuccess} btn-info-2`}
                                  onClick={() => {
                                    // gohere(againitem.amazon_link);
                                    props.storeLinkClick(againitem.id, againitem.amazon_link);
                                  }}
                                >
                                  <i className="fa fa-sign-in"></i>
                                  {
                                  
                                  t("Button.gostore")}
                                </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                  ))}
                  {/* </Slider> */}
            {/* </Row> */}
              </Carousel>

            </>
  )

})


      }

    </div >
  );
};

export default CartContent;
