import React from "react";
import { useTranslation } from "react-i18next";
import style from "./profile.module.scss";
import { useSelector } from "react-redux";

function FormHead(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const buttonClick = () => {
    props.change(props.status);
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_FormHead}`
          : `${style.FormHead}`
      }`}
    >
      <h3
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_headeText}`
            : `${style.headeText}`
        } text-white`}
      >
        {t("Common." + props.title)}
      </h3>
      <button
        className={`${style.editButton} edit-button`}
        onClick={buttonClick}
        hidden={!props.show}
      >
        <i className="fa fa-pencil"></i>
      </button>
    </div>
  );
}

export default FormHead;
