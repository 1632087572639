import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

function TravelPaymentFail(props) {    
    return (
        <div className="h-500" style={{"marginTop" : "10%"}}>
           <center><h2>Payment failed </h2></center><br/><br/>
           <center><h3>Please go to travels package view for further purchase </h3></center>
        </div>
    );
}
export default withTranslation()(withRouter(TravelPaymentFail));