import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
// import {Redirect}           from 'react-router-dom';
import { Alert, Button, Col, Image, Row } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
//components
import Forms from "../../../components/auth/Forms";
import LoginButton from "../../../components/auth/LoginButton";

//service
import LoginService from "../../../../service/Auth/Login";
import { islogged } from "../../../../store/action";
import UserStore from "../../../../store/UserStore";
// import style from "../../../components/auth/auth.module.scss";

import newStyle from "../../../components/auth/login_page.module.scss"
import { COMPANY_NAME, BASE } from "../../../../configuration/apiconfig";

import { useParams } from "react-router-dom";
import AppInfoservice from "../../../../service/common/Appinfo";
import { useDispatch, useSelector } from "react-redux";

import JSEncrypt from "jsencrypt";
import loginPage from '../../../../assets/images/auth/loginPage.png'
import { IMG_URL } from "../../../../configuration/apiconfig";
import { Input, Label } from "reactstrap";

import apple from "../../../../assets/images/apple.png"
import android from "../../../../assets/images/android.png"

const formValid = (formError) => {
  let valid = true;
  Object.values(formError).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
const mapStateToProps = (state) => {
  return { proPs: state };
};
function mapDispatchToProps(dispatch) {
  console.log("dispatc", dispatch);
  return bindActionCreators({ islogged }, dispatch);
}

function Login(proPs) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const deviceWidth = Number(window.innerWidth);

  const [logo,setLogo]=useState()
  const service = new AppInfoservice();

  const params = useParams();
  useEffect(() => {
    if (currentTheme === "theme-dark") {
      const body = document.querySelector("body");
      body.classList.remove("theme-light");
      body.classList.remove("left-side-style");
      body.classList.add("theme-dark");
    } else if (currentTheme === "left-side-style") {
      var element = document.getElementById("myid");
      const body = document.querySelector("body");
      body.classList.remove("theme-light");
      body.classList.remove("theme-dark");
      body.classList.remove("theme-light");
      body.classList.remove("theme-dark");
      body.classList.add("left-side-style");
    } else {
      var element = document.getElementById("myid");
      const body = document.querySelector("body");
      body.classList.remove("left-side-style");
      body.classList.remove("theme-dark");
      body.classList.remove("theme-dark");
      body.classList.remove("left-side-style");
      body.classList.add("theme-light");
    }

    const root = document.documentElement;
    root?.style.setProperty("--is-login-contain-width",deviceWidth < 768 ? '98%' : '60%');
    root?.style.setProperty("--is-login-contain-padding",'10px');
    root?.style.setProperty("--is-login-contain-margin-top",'2%');
    root?.style.setProperty("--is-logo-image-width",'none');
    root?.style?.setProperty("--is-login-font-color",currentTheme === "theme-dark" ? 'white' : 'black')

    service.Appinfo().then((res)=>{
        if(res.status){
          setLogo(res.data.company_info.favicon)
        }else{
            
        }
    })
  }, []);
  // const urlParams = new URLSearchParams(window.location.search);
  // let code = urlParams.get('token');
  // let key = urlParams.get('key');
  // const dispatch = useDispatch();
  // const history = useHistory();

  // useEffect(()=>{
  //     if(code && key){
  //       var decrypt = new JSEncrypt();
  //       decrypt.setPrivateKey(process.env.REACT_APP_PRI);
  //       var uncrypted = decrypt.decrypt(code.replace(/ /g,'+'));
  //       localStorage.setItem('apiKey',key)
  //       const service = new AppInfoservice();
  //       let token ={
  //           token : uncrypted
  //       }
  //       service.check_token(token).then((res)=>{

  //         if(res.status){
  //             localStorage.setItem('login', JSON.stringify({
  //                 token: token.token,
  //             }));
  //             UserStore.key = token.token;
  //             UserStore.isLoggedIn = true;
  //             dispatch(islogged());
  //             console.log(window.location.pathname);
  //             // history.push(window.location.pathname)
  //             history.push('/dashboard')
  //         }
  //         else{
  //             history.push('/login')

  //         }

  //       })

  //     }

  //    },[])

  const { t } = useTranslation();

  const [state, setState] = useState({
    userName: params.username ? params.username : "",
    password: params.username && params.adminusername ? "123456" : "",
    adminuser: params.adminusername ? params.adminusername : "",
    isLoggedError: false,
    isLogged: false,
    unapproved: false,
    FormError: {
      userName: "",
      password: "",
      adminuser: "",
    },
    btnDisabled: false,
    loader: false,
  });

  onchange = (e) => {
    e.preventDefault();
    const { name, value } = e.target; //get the field name
    state[name] = value;
    state.FormError[name] = "";
    setState({...state});
  };
  console.log('fff :: ', state)
  const resetform = () => {
    document.getElementById("login-form").reset(); //reset form
    setState((prevState) => ({
      //reset state
      ...prevState,
      userName: "",
      password: "",
    }));
  };

  const handleAlertDismiss = () => {
    setState((prevState) => ({
      ...prevState,
      isLoggedError: false,
    }));
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "userName":
        FormError.userName = value.length === 0 ? "required" : "";
        break;
      case "password":
        FormError.password = value.length === 0 ? "required" : "";
        break;
      case "adminuser":
        FormError.adminuser = value.length === 0 ? "required" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault(); //prevent default
    requiredValid(state.userName, "userName");
    requiredValid(state.password, "password");
    // requiredValid(state.adminuser, "adminuser");
    const service = new LoginService();
    if (formValid(state.FormError)) {
      setState((prevState) => ({
        ...prevState,
        loader: true,
      }));
      service.getApi(state.adminuser).then(async (res) => {
        if (res.status) {
          let cridential = {
            username: state.userName,
            password: state.password,
          };
          setState((prevState) => ({
            ...prevState,
            loader: false,
          }));
          await localStorage.setItem("apiKey", res.data.key);
          UserStore.apiKey = res.key;
          await service.LoginUser(cridential).then((res) => {
            if (res.status) {
              resetform();
              localStorage.setItem(
                "login",
                JSON.stringify({
                  token: res.data.access_token,
                })
              );
              UserStore.key = res.data.access_token;
              UserStore.isLoggedIn = true;
              setState((prevState) => ({
                ...prevState,
                isLogged: true,
              }));
              proPs.islogged();
            } else if (res.status.unapproved === true) {
              setState((prevState) => ({
                ...prevState,
                isLogged: false,
                unapproved: true,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                isLoggedError: true,
              }));
            }
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            loader: false,
          }));
          if (res.error.code === 1042) {
            setState((prevState) => ({
              ...prevState,
              isLoggedError: true,
            }));
          } else if (res.error.code === 1001) {
            setState((prevState) => ({
              ...prevState,
              isLoggedError: true,
            }));
          }
        }
      });
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="mb-2 h-100">
        <Helmet>
          <title>{COMPANY_NAME}</title>
        </Helmet>
        {proPs.isLogged || state.isLogged ? <Redirect to="/dashboard" /> : ""}

        {state.unapproved ? <Redirect to="/unapproved/dashboard" /> : ""}

        {state.isLoggedError ? (
          <Alert dismissible variant="danger" onClose={handleAlertDismiss}>
            {t("Login.Error.LoginError")}
          </Alert>
        ) : (
          ""
        )}
        <div className="h-100 align-items-center login-container-div">
          <div className="left-section">
            <div className="first-div">
              <div className="left-content w-100">
                <div>
                  <h1 className="text-white">Hello ! Welcome back</h1>
                  <h5 className="text-white">Log in with your data that you entered during your registration.</h5>
                </div>
              </div>
              <div className="w-100">
                <div>
                  <div>
                    <div className="download-info">
                      <Link target="_blank" to="https://apps.apple.com/ph/app/cfcc-members/id6449962504" className="d-flex align-items-center gap-1">
                        <Image src={apple} height={30}/>
                        <label className="mb-0">Download for iOS</label>
                      </Link>
                      <Link target="_blank" to="https://play.google.com/store/apps/details?id=com.cfcashbackcommunity.cfcc" className="d-flex gap-1">
                        <Image src={android} height={30}/>
                        <label className="mb-0">Download for Android</label>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <form id="login-form" className="login-form py-5">
              <div className="login-form-content">
                <div className={`${newStyle?.login_form_content} justify-content-center`}>
                  <div className={'${newStyle?.login_form_content_div} w-100'}>
                    <div className="d-none">
                      <div className={newStyle?.form_heading}>
                        <h1>Hello ! Welcome back</h1>
                      </div>
                      <div>
                        <h6 className={newStyle?.form_sub_heading}>Log in with your data that you entered during your registration.</h6>
                      </div>
                    </div>
                    <div className={newStyle?.form_content}>
                      <div className="heading">
                        <h4>LOG IN</h4>
                      </div>
                      <div className={newStyle?.form_content}>
                        <Input
                          type="text"
                          name="userName"
                          onChange={(e) => {
                            onchange(e)
                          }}
                          value={state.userName}
                          placeholder="Username"
                          className={`${newStyle?.form_input} ${
                            currentTheme == "theme-dark" && `${newStyle?.dark_mode_textArea}`
                          }`}
                        />
                        { state.FormError?.userName?.length > 0 && <span className={'text-danger'}>Required</span> }
                      </div>
                      <div className={newStyle?.form_content}>
                        <Input
                          type="password"
                          name="password"
                          onChange={(e) => {
                            onchange(e)
                          }}
                          value={state.password}
                          placeholder="Password"
                          className={`${newStyle?.form_input} ${
                            currentTheme == "theme-dark" && `${newStyle?.dark_mode_textArea}`
                          }`}
                        />
                        { state.FormError?.password?.length > 0 && <span className={'text-danger'}>Required</span> }
                      </div>
                      <div className="text-center">
                        {/* <Link to="/forgotPassword">{t("Common.forgotPassword")}?</Link> */}
                      </div>
                      <div
                        className={`d-none ${newStyle?.form_forget_link} ${
                          currentTheme == "theme-dark"
                            ? `${newStyle?.dark_mode_ForgotSection}`
                            : `${newStyle?.ForgotSection}`
                        }`}
                      >
                        <div className="text-center">
                          <Link to="/forgotPassword">{t("Common.forgotPassword")}?</Link>
                        </div>
                      </div>

                      <LoginButton
                        submit={onSubmit}
                        disabled={state.btnDisabled}
                        {...state}
                        btnClass={newStyle?.form_btn}
                      />
                      <div className="mt-2 mb-5">
                        <div className="text-center forget-link">
                          {/* <Link to="/forgotPassword">{t("Common.forgotPassword")}?</Link> */}
                          <Link to="/forgotPassword">{t("Common.forgotPassword")}?</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
);
