
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo8.infinitemlmdemo.com/Michael_Jordan/backoffice/api/";
// const IMG_URL = "http://demo8.infinitemlmdemo.com/Michael_Jordan/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/Michael_Jordan/";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = '4eb2f3f2-d2bf-4255-a09b-c83609aca69f' //demo's old api key
// // const DEFAULT_KEY = '626b9ff1-bcd7-4170-8efb-52ff0c8346c1'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY

// }



import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://admin.crowdfundedcashbackcommunity.com/backoffice/api/";
// const IMG_URL = "https://admin.crowdfundedcashbackcommunity.com/uploads/images/";
// const BASE = "https://admin.crowdfundedcashbackcommunity.com/";
const BASE_URL = "https://admin.cfcc-amp.com/backoffice/api/";
const IMG_URL = "https://admin.cfcc-amp.com/uploads/images/";
const BASE = "https://admin.cfcc-amp.com/";
// const BASE_URL = "https://demoadmin.crowdfundedcashbackcommunity.com/backoffice/api/";
// const IMG_URL = "https://demoadmin.crowdfundedcashbackcommunity.com/uploads/images/";
// const BASE = "https://demoadmin.crowdfundedcashbackcommunity.com/";
const API_KEY =localStorage.getItem('apiKey');
const DEFAULT_KEY = '4eb2f3f2-d2bf-4255-a09b-c83609aca69f' //demo's old api key
// const DEFAULT_KEY = '626b9ff1-bcd7-4170-8efb-52ff0c8346c1'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY
}