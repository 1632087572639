import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
//import CartContent from '../../components/shopping/CartContent';
import Spinner from '../../shared/Spinner';
import shoppingService from '../../../service/shopping';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
//import { useHistory } from 'react-router';
import RepurchasePackage from '../../components/shopping/RepurchasePackage';




function PackageDetails(props){
    let service = new shoppingService();    
    const [state, setState] = useState({
        data: [],
        loader: true,
        cart : {},
        ProductId  :''

    })

    const buttonGroups = [
    ];
    
    useEffect(() => {
        const {id} = props.match.params;
        service.getRepurchasePackage(id)
            .then(res => {
                if(res.status) {
                    setState(prevState => ({
                        ...prevState,
                        data: res.data, 
                        loader: false,
                        cart : res.data.cart.quantity,
                        ProductId : id
                    }));
                } else {
                    if(res.error.code ===1002){
                        props.history.push('/logout');
                    }else if(res.error.code === 1049){
                        props.history.push({
                            pathname : '/shopping',
                            state : {
                                error : true,
                                message : 'invalidProductId'
                            }
                        });
                    }else if(res.error.code ===1057){
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message : 'permissionDenied'
                            }
                        })
                    }
                }
            })
       


    }, []);
    // const showRepurchaseReport = () => {
    //     alert('show showRepurchaseReport');
    // }


    //update cart
    const updateCart = (quantity) =>{
        if(state.cart > 0){
            service.updateCart(state.data.cart.rowid,quantity).then(res=>{
                if(res.status){
                    props.history.push({
                        pathname : '/shopping',
                        state : {
                            success : true,
                            update : true,
                            productName : state.data.product.product_name                        }
                    });
                }else{
                    if(res.error.code === 1002){
                        props.history.push('/logout')
                    }
                }
            })
        }else{
            const shoptype = 'repurchase'

            const formData = {
                product_id : state.ProductId,
                product_qty : quantity,
                shop_type : shoptype
            }
            service.addToCart(formData).then(res=>{
                if(res.status){
                    props.history.push({
                        pathname : '/shopping',
                        state : {
                            success : true,
                            update : false,
                            productName : state.data.product.product_name                        }
                    });
                }else{
                    if(res.error.code === 1002){
                        props.history.push('/logout')
                    }
                }
            })
        }    
    } 

    const {t} = props;
    if(state.loader) {
        return <Spinner />
    }

    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.product_details')}</title>
            </Helmet>
            <PageTitle title="product_details" buttonOn={false} group={buttonGroups}/>
            <RepurchasePackage 
                updateCart={updateCart}
                {...state.data}
            />
        </div>


    );

}
export default withTranslation()(withRouter(PackageDetails)); 